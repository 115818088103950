import React from 'react'
import {Link} from 'react-router-dom'
import '../../assets/scss/QuickSearchWithCompanyFilter.scss'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import ButtonWithCloseIcon from "./ButtonWithCloseIcon"


const QuickSearchWithFilter = ({
 linkTo = "",
 linkTitle = "",
 companies = [],
 query = "",
 companyFilter = "all",
 addQuery,
 addCompanyFilter
}) => {

  const options = [{ label: 'All Companies', value: 'all' }, ...companies.map(i => ({ label: i.display_name, value: i.company_id }))]

  return (
    <section className="quick-search">
      <form onSubmit={e => e.preventDefault()}>
        <div className="quick-search-container">
          <input
            type="text"
            placeholder="Quick search"
            value={query}
            onChange={e => addQuery(e.target.value)}
          />
          {query.length > 0 &&
            <ButtonWithCloseIcon onClick={e => addQuery("")} type="reset" />
          }
        </div>

        <Select
          id="filter-by-company-id"
          name="filter"
          placeholder="Please select company..."
          value={options.find(i => i.value === companyFilter)}
          onChange={selected => addCompanyFilter(selected.value)}
          options={options}
        />

      </form>

      <Link to={linkTo} className="btn" data-tip="Click to add record">{linkTitle}</Link>
      <ReactTooltip />

    </section>
  )
}

export default QuickSearchWithFilter