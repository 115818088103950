import ACTION from '../../constants/actionType'
import {auth} from "../index"
import {addError} from "../error"


const isFetching = (fetching = false) =>
  ({
    type: ACTION.AUTH_FETCHING,
    payload: fetching
  })


export const signOut = doc => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    await auth.signOut()
  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}