import AddSuperAdmin from '../../ui/SuperAdmins/AddSuperAdmin'
import {connect} from 'react-redux'
import {addDoc} from '../../../actions/super_admins/addDoc'
import {addError} from '../../../actions/error'


const mapStateToProps = (state, props) =>
  ({
    fetching: state.super_admins.fetching
  })

const mapDispatchToProps = dispatch =>
  ({
    addDoc(doc) {
      dispatch(
        addDoc(doc)
      )
    },
    addError(error) {
      dispatch(
        addError(error)
      )
    }
  })


export default connect(mapStateToProps, mapDispatchToProps)(AddSuperAdmin)