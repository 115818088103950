import React, {Component} from 'react'
import _ from 'lodash'
import Button from './Button'
import moment from 'moment'
import DateRangePickerWrapper from './DateRangePickerWrapper'
import FeedbackTable from './FeedbackTable'
import Header from '../../container/Header'
import EntryHeader from '../EntryHeader'
import ShowErrors from '../../container/ShowErrors'
import '../../../assets/scss/Analytics/Analytics.scss'
import FeedbackBarGraph from './FeedbackBarGraph'
import ReactTooltip from 'react-tooltip'
import Select from "react-select"
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import LegalInfoFooter from "../LegalInfoFooter"
import {getDates, getPatients, getHCP, getData, getOptions} from "./selectors"


class Analytics extends Component {

	state = {
		startDate: moment().subtract(7, 'd'),
		endDate: moment(),
		company_id: { label: 'All Companies', value: 'all' }
	}

	componentDidMount() {
		this.fetchData = _.debounce(this.fetchData, 1000)
	}

	fetchData = () => {
		const {startDate, endDate, company_id} = this.state
		if (startDate !== null && endDate !== null) {
			this.props.fetchFeedback(startDate, endDate, company_id.value)
		}
	}

	onDatesChange = (startDate, endDate) => {
		this.setState({
			startDate: startDate,
			endDate: endDate
		}, this.fetchData())

	}

	render() {
		const {docs, fetching} = this.props
		const {startDate, endDate, company_id} = this.state


		return (
			<main className="app analytics">

				{fetching &&
					<ProgressBarIndeterminate />
				}

				<ShowErrors/>
				<Header/>
				<article className="inner-content">

					<EntryHeader title="Analytics"/>

					<div className="analytics-controls">
						<Select
							id="filter-by-company-id"
							name="filter"
							value={company_id}
							onChange={selected => this.setState({ company_id: selected }, this.fetchData())}
							options={getOptions(this.props)}
						/>

						<DateRangePickerWrapper
							startDate={startDate}
							endDate={endDate}
							onDatesChange={this.onDatesChange}
						/>

						<Button
							data={getData(this.props)}
						/>

					</div>

					{docs.length > 0 &&
						<>
							<aside className="analytics-graphs">
								<FeedbackBarGraph
									dates={getDates(this.props)}
									patients={getPatients(this.props)}
									hcp={getHCP(this.props)}
								/>
							</aside>
							<h3>FEEDBACK</h3>
							<FeedbackTable data={docs}/>
						</>
						}

					{docs.length === 0 &&
						<p>No data</p>
					}
					<ReactTooltip/>
				</article>
				<LegalInfoFooter />
			</main>
		)
	}
}

export default Analytics
