import ACTION from '../../constants/actionType'
import {auth, functions} from "../index"
import {addError} from "../error"


const isFetching = (fetching = false) =>
  ({
    type: ACTION.AUTH_FETCHING,
    payload: fetching
  })

export const setStep = (step = 1) =>
  ({
    type: ACTION.AUTH_STEP,
    payload: step
  })

const setUser = user =>
  ({
    type: ACTION.AUTH_LOGIN,
    payload: user
  })

export const signInWithPhoneNumber = (
  admin_id = ""
) => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    const accessToken = functions.httpsCallable('getSuperAdminTelephone')

    const {
      data: {
        telephone
      }
    } = await accessToken({
      admin_id: admin_id
    })

    window.confirmationResult = await auth.signInWithPhoneNumber(telephone, window.recaptchaVerifier)

    dispatch(
      setStep(2)
    )

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}


export const signInWithConfirmationCode = (
  code = ""
) => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    const userCredential = await window.confirmationResult.confirm(code)

    const idTokenResult = await userCredential.user.getIdTokenResult()

    if (!!idTokenResult.claims.super_admin) {

      dispatch(
        setUser(userCredential.user)
      )

    } else {
      dispatch(
        addError("No admin custom claims")
      )
    }

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}

