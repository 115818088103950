import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Timestamp} from '../../../actions'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/Patients/Add.scss'
import getKeywords from '../../../util/getKeywords'
import getPassword from '../../../util/getPassword'
import ReactTooltip from "react-tooltip"
import Select from "react-select"
import ButtonCloseWrapper from "../ButtonWithCloseIcon"
import LegalInfoFooter from "../LegalInfoFooter"


class AddPatient extends Component {

	state = {
		patient_id: "",
		password: getPassword(),
		company_id: null,
		online: {
			status: false,
			last_updated: Timestamp.now()
		},
		is_auth: false,
		keywords: [],
		registered: Timestamp.now()
	}

	handleChange = e => this.setState({[e.target.name]: e.target.value})

	handleDelete = e => {
		e.preventDefault()
		this.setState({
			patient_id: "",
			password: getPassword(),
			company_id: null,
			online: {
				status: false,
				last_updated: Timestamp.now()
			},
			is_auth: false,
			keywords: [],
			registered: Timestamp.now()
		})
	}

	handleSubmit = e => {
		e.preventDefault()

		const { patient_id, company_id, password, is_auth, registered } = this.state

		if (!company_id) {
			this.props.addError("Please select a company id")
		} else if (patient_id.length === 0) {
			this.props.addError("Please enter a Patient ID")
		} else if (password.length === 0) {
			this.props.addError("Please enter a password")
		} else {
			const data = {
				patient_id: patient_id.toLowerCase().replace(/\s/g, ''),
				password: password.toLowerCase().replace(/\s/g, ''),
				is_auth: is_auth,
				registered: registered,
				company_id: company_id.value
			}

			data.keywords = Array.from(new Set([
				...getKeywords(data.patient_id.toLowerCase())
			]))

			this.props.addDoc(data)
			this.props.history.push('/patients')
		}

	}

	handleFieldReset = e => {
		e.preventDefault()
		const field = e.currentTarget.dataset.field ?? ""
		if(!!field){
			this.setState({
				[field]: ""
			})
		}
	}

	render() {
		const { patient_id, company_id, password } = this.state

		const { companies } = this.props

		const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

		return (
			<main className="app add-patient">
				<ShowErrors/>
				<Header/>
				<article className="inner-content">

					<EntryHeader title="Add Patient"/>

					<section className="content">

						<h3>New Patient</h3>

						<Link
							to="/patients"
							className="close"
							data-tip={`Click to close`}>
							<img
								alt="close icon"
								src={close}/>
						</Link>

						<form
							className="add-patient-form"
							onSubmit={this.handleSubmit}
							onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
						>

							<div className="company_id">
								<label data-tip={`The Company ID for the item`}>Company ID</label>
								<Select
									name="company_id"
									value={company_id}
									placeholder="Please select company..."
									onChange={selected => this.setState({ company_id: selected })}
									options={options}
								/>
								<p className="form-help">Please a company for the Healthcare Professional</p>
							</div>

							<div className="patient_id">
								<label data-tip={`Patient ID`}>Patient ID</label>
								<input
									tabIndex="10"
									name="patient_id"
									type="text"
									placeholder="Please enter a Patient ID..."
									value={patient_id}
									onChange={this.handleChange}
								/>
								{patient_id.length > 0 &&
									<ButtonCloseWrapper data-field="patient_id" onClick={this.handleFieldReset} type="reset"/>
								}
								<p className="form-help">(patient ID auto generated)</p>
							</div>

							<div className="password">
								<label data-tip={`Password for Patient ID`}>Password</label>
								<input
									tabIndex="20"
									name="password"
									type="text"
									placeholder="Please enter a Password..."
									value={password}
									onChange={this.handleChange}
								/>
								{password.length > 0 &&
								<ButtonCloseWrapper data-field="password" onClick={this.handleFieldReset} type="reset"/>
								}
								<p className="form-help">(password auto generated)</p>
							</div>

							<input
								tabIndex="30"
								className="reset"
								onClick={this.handleDelete}
								type="image"
								alt="reset form entries"
								src={trashIcon}
								data-tip={`Click to reset record`}
							/>

							<input
								tabIndex="40"
								data-tip={`Click to add record`}
								className="add btn"
								type="submit"
								value="ADD PATIENT"
							/>

							<ReactTooltip/>

						</form>

					</section>
				</article>
				<LegalInfoFooter />
			</main>
		)
	}
}

export default AddPatient