import styled from "styled-components"

const EventWrapperWrapper = styled.span`
	display: block;
	position: relative;
  cursor: move;
  cursor: grab;
  
  &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
	}
`

export default EventWrapperWrapper