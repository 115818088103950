import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import _ from "lodash"


const addDoc = doc =>
  ({
    type: ACTION.COMPANY_ADMIN_NEW_DOC,
    payload: doc
  })

const updateDoc = doc =>
  ({
    type: ACTION.COMPANY_ADMIN_UPDATE_DOC,
    payload: doc
  })

const deleteDoc = doc =>
  ({
    type: ACTION.COMPANY_ADMIN_DELETE_DOC,
    payload: doc
  })


export const companyAdminsDocListener = () => async (dispatch, getState) => {

  if(_.isFunction(window.companyAdminsDocListener)){
    window.companyAdminsDocListener()
    delete window.companyAdminsDocListener
  }

  window.companyAdminsDocListener = db.collection(COLLECTION.COMPANY_ADMINS)
    .onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        const doc = {
          id: change.doc.id,
          ...change.doc.data()
        }

        switch (change.type) {
          case 'added':
            dispatch(
              addDoc(doc)
            )
            break
          case 'modified':
            dispatch(
              updateDoc(doc)
            )
            break
          case 'removed':
            dispatch(
              deleteDoc(doc)
            )
            break
          default:
            break
        }

      })
    })

}