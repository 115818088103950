import React, {useRef, useEffect, useState} from 'react'
import Chart from "chart.js"
import '../../../assets/scss/Analytics/FeedbackBarGraph.scss'


Chart.defaults.global.defaultFontFamily = "'Open Sans', sans-serif"

const chartConfig = {
	type: "bar",
	data: {
		labels: [],
		datasets: [
			{
				label: "Patients",
				data: [],
				borderWidth: 1,
				backgroundColor: '#D3007D',
				borderColor: '#D3007D'
			},
			{
				label: "HCP",
				data: [],
				borderWidth: 1,
				backgroundColor: '#51338B',
				borderColor: '#51338B'
			}
		]
	},
	options: {
		scales: {
			yAxes: [{
				ticks: {
					min: 0,
					max: 5,
					stepSize: 1,
					beginAtZero: true
				}
			}]
		},
		animation: false
	}
}

const FeedbackBarGraph = ({dates, patients, hcp}) => {

	const chartContainer = useRef(null)
	const [chartInstance, setChartInstance] = useState(null)

	useEffect(() => {
		if (chartContainer && chartContainer.current) {
			const newChartInstance = new Chart(chartContainer.current, chartConfig)

			newChartInstance.data.labels = []
			newChartInstance.data.datasets.forEach(dataset => dataset.data = [])
			newChartInstance.data.labels.push(...dates)
			const data = [patients, hcp]
			newChartInstance.data.datasets.forEach((dataset, index) => dataset.data.push(...data[index]))
			newChartInstance.update()

			setChartInstance(newChartInstance)
		}
	}, [chartContainer])


	useEffect(() => {
		if (chartInstance) {
			chartInstance.data.labels = []
			chartInstance.data.datasets.forEach(dataset => dataset.data = [])
			chartInstance.data.labels.push(...dates)
			const data = [patients, hcp]
			chartInstance.data.datasets.forEach((dataset, index) => dataset.data.push(...data[index]))
			chartInstance.update()
		}
	}, [patients, hcp, dates])

	return (
		<div className="feedbackGraphContainer">
			<h3>RATINGS <span>Patients vs. HCP</span></h3>

			<canvas
				id="feedbackBarGraph"
				ref={chartContainer}
			/>
		</div>
	)

}


export default FeedbackBarGraph
