import React, {useEffect, useState} from 'react'
import AsyncSelect from 'react-select/async'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment from "moment"
import SlideDrawerWrapper from './Styled/SlideDrawerWrapper'
import DrawerContent from './Styled/DrawerContent'
import DrawerHandel from './Styled/DrawerHandel'
import Backdrop from './Styled/Backdrop'
import H2Wrapper from './Styled/H2Wrapper'
import FormField from './Styled/FormField'
import LabelWrapper from './Styled/LabelWrapper'
import AddAppointmentButton from './Styled/AddAppointmentButton'
import FormFieldWrapper from './Styled/FormFieldWrapper'
import {db} from '../../../../actions/index'
import COLLECTION from "../../../../constants/collections";
import _ from 'lodash'


const SlideDrawer = ({
	closeDrawer = false,
	setAppointment
}) => {

	const [showValue, setShowValue] = useState(false)
	const [hcpValue, setHcpValue] = useState(null)
	const [patientValue, setPatientValue] = useState(null)
	const [dateValue, setDateValue] = useState("")
	const [companyIDValue, setCompanyIDValue] = useState("all")


	useEffect( ()=>{
		setTimeout(()=> setShowValue(true), 200)
	}, [closeDrawer])


	const loadHCP = async (hcp_id) => {
			const querySnapshot = await db.collection(COLLECTION.HEALTH_CARE_PROFESSIONALS)
				.where("keywords", "array-contains", hcp_id.toLowerCase().replace(/\s/g, ''))
				.get()

			return querySnapshot.empty ? [] : querySnapshot.docs.map(i => ({id: i.id, label: i.data().hcp_id, value: i.data().hcp_id, ...i.data()}))
		}

	const loadPatients = _.debounce(
		async (patient_id) => {
			const querySnapshot = await db.collection(COLLECTION.PATIENTS)
				.where('company_id', '==', companyIDValue)
				.where("keywords", "array-contains", patient_id.toLowerCase().replace(/\s/g, ''))
				.get()

			return querySnapshot.empty ? [] : querySnapshot.docs.map(i => ({id: i.id, label: i.data().patient_id, value: i.data().patient_id, ...i.data()}))
		},
		300,
		{ 'maxWait': 1000 }
		)


	const close = () => {
		setShowValue(false)
		setTimeout(() => closeDrawer(), 200)
	}


	const addAppointment = async () => {
		if(!!hcpValue && !!patientValue && !!dateValue){
			await setAppointment(hcpValue, patientValue, dateValue)
			close()
		}
	}


	return(
		<>
			<SlideDrawerWrapper show={showValue}>
				<DrawerContent>
				<H2Wrapper>Add Appointment</H2Wrapper>

				<FormField>
					<LabelWrapper data-tip={`The Healthcare Professional ID`}>Healthcare Professional ID</LabelWrapper>
					<AsyncSelect
						cacheOptions
						id="hcp_id"
						key="hcp_id"
						name="hcp_id"
						placeholder="Please begin typing for suggestions below"
						loadOptions={loadHCP}
						defaultOptions
						onChange={selected => {
							setHcpValue(selected)
							setCompanyIDValue(selected.company_id)
						}}
					/>
				</FormField>

				<FormField>
					<LabelWrapper data-tip={`The Patient ID`}>Patient ID</LabelWrapper>
					<AsyncSelect
						cacheOptions
						id="patient_id"
						key="patient_id"
						name="patient_id"
						isDisabled={ companyIDValue === "all" }
						placeholder="Please begin typing for suggestions below"
						loadOptions={loadPatients}
						defaultOptions
						onChange={selected => setPatientValue(selected)}
					/>
				</FormField>

					<FormFieldWrapper>
						<LabelWrapper data-tip={`Appointment Date and Time`}>Appointment Date and Time</LabelWrapper>

						<Datetime
							dateFormat="D/M/YYYY"
							value={moment(dateValue)}
							onChange={ date => setDateValue(date)}
							closeOnSelect={true}
							isValidDate={(currentDate, selectedDate) => {
								if(!selectedDate){
									return currentDate.isAfter(moment().subtract(1, 'd'))
								} else {
									return selectedDate.isAfter(moment().subtract(1, 'd'))
										&& currentDate.isAfter(moment().subtract(1, 'd'))
								}
							}}
						/>
					</FormFieldWrapper>

					<AddAppointmentButton onClick={() => addAppointment()}>ADD APPOINTMENT</AddAppointmentButton>
				</DrawerContent>
				<DrawerHandel onClick={() => close()} />
			</SlideDrawerWrapper>
			<Backdrop />
		</>
	)
}

export default SlideDrawer