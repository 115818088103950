import config from '../constants/config'
import {firebase} from '@firebase/app'
import '@firebase/analytics'
import '@firebase/firestore'
import '@firebase/functions'
import '@firebase/auth'
import '@firebase/storage'
import Region from "../constants/region"

firebase.initializeApp(config)
firebase.analytics()


export const LOCAL = firebase.auth.Auth.Persistence.LOCAL
export const FieldValue = firebase.firestore.FieldValue
export const Timestamp = firebase.firestore.Timestamp
export const db = firebase.firestore()
export const auth = firebase.auth()
export const functions = firebase.app().functions(Region)
export const storage = firebase.storage()
export const storageRef = storage.ref()
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier

