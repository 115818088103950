import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import '../../assets/scss/QuickSearch.scss'
import ReactTooltip from 'react-tooltip'
import ButtonCloseWrapper from "./ButtonWithCloseIcon"


const QuickSearch = ({handleQuickSearch, entries, linkTo, linkTitle}) => {

  const handleReset = e => {
    e.preventDefault()

    setQueryValue("")
    handleQuickSearch("")
  }

  const handleQuickSearchChange = e => {
    setQueryValue(e.target.value)
    handleQuickSearch(e.target.value.toLowerCase())
  }

  const [queryValue, setQueryValue] = useState('')

  return (
    <section className="quick-search">
      <form onSubmit={ e => e.preventDefault()}>
        <div className="quick-search-container">
          <input type="text" placeholder="Quick search" value={queryValue}
                 onChange={handleQuickSearchChange} />
          {queryValue.length > 0 &&
            <ButtonCloseWrapper onClick={handleReset} type="reset" />
          }
        </div>

        {entries > 0 &&
          <p>{entries} entries</p>
        }
      </form>

      <Link to={linkTo} className="btn" data-tip="Click to add record">{linkTitle}</Link>
      <ReactTooltip />

    </section>
  )
}

export default QuickSearch