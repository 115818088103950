import ACTION from '../../../constants/actionType'

const initialState = []

const errors = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ERROR_NEW_DOC:
      return [
        ...state,
        action.payload
      ]
    case ACTION.ERROR_DELETE_DOC:
      return state.filter((message, i) => i !== action.payload)
    default:
      return state
  }
}


export default errors