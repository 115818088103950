import React from 'react'
import styled from 'styled-components'


const ProgressBarDeterminateWrapper = styled.div`
  position:absolute;
  width:100%;
  height:5px;
  overflow-x: hidden;
  background:rgba(74,141,248, 0.25);
 
  > div{
    transition: width .3s linear;
    background-color: rgb(74,141,248);
    top: 0;
    bottom: 0;
    position: absolute;
    width: ${props => props.progress}%;
  }
`

const ProgressBarDeterminate = ({progress}) =>
	<ProgressBarDeterminateWrapper progress={ progress }>
		<div/>
	</ProgressBarDeterminateWrapper>


export default ProgressBarDeterminate