import ACTION from '../../../constants/actionType'
import {combineReducers} from 'redux'
import _ from 'lodash'


const fetching = (state = false, action) =>
  (action.type === ACTION.TWILIO_ROOMS_FETCHING_DOCS) ?
  action.payload :
  state


const docs = (state = [], action) => {

  let newDoc, docID, docIndex


  switch (action.type) {
    case ACTION.TWILIO_ROOMS_NEW_DOC:
      newDoc = action.payload
      docID = newDoc.id
      docIndex = _.findIndex(state, doc => doc.id === docID)

      return docIndex < 0 ? [...state, newDoc] : state
    case ACTION.TWILIO_ROOMS_UPDATE_DOC:
      newDoc = action.payload
      docID = newDoc.id
      docIndex = _.findIndex(state, doc => doc.id === docID)
      return [
        ...state.slice(0, docIndex),
        newDoc,
        ...state.slice(docIndex + 1)
      ]
    case ACTION.TWILIO_ROOMS_DELETE_DOC:
      newDoc = action.payload
      docID = newDoc.id
      return _.reject(state, doc => doc.id === docID)
    case ACTION.TWILIO_ROOMS_CLEAR_DOCS:
      return []
    default:
      return state
  }

}


export default combineReducers({
  docs,
  fetching
})