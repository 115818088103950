import AddCompanyAdmin from '../../ui/CompanyAdmins/AddCompanyAdmin'
import {connect} from 'react-redux'
import {addDoc} from '../../../actions/company_admins/addDoc'
import {addError} from '../../../actions/error'


const mapStateToProps = (state, props) =>
  ({
    companies: state.companies.docs,
    fetching: state.super_admins.fetching
  })

const mapDispatchToProps = dispatch =>
  ({
    addDoc(doc) {
      dispatch(
        addDoc(doc)
      )
    },
    addError(error) {
      dispatch(
        addError(error)
      )
    }
  })


export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyAdmin)