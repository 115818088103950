import SuperAdmins from '../../ui/SuperAdmins'
import {connect} from 'react-redux'
import {deleteDoc, setDoc} from '../../../actions/super_admins'
import {addError} from '../../../actions/error'


const mapStateToProps = (state, props) =>
  ({
    docs: state.super_admins.docs,
    fetching: state.super_admins.fetching
  })

const mapDispatchToProps = dispatch =>
  ({
    deleteDoc(doc) {
      dispatch(
        deleteDoc(doc)
      )
    },
    setDoc(doc) {
      dispatch(
        setDoc(doc)
      )
    },
    addError(error) {
      dispatch(
        addError(error)
      )
    }
  })


export default connect(mapStateToProps, mapDispatchToProps)(SuperAdmins)