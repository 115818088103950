import Analytics from '../../ui/Analytics'
import {connect} from 'react-redux'
import {feedbackListener} from '../../../actions/analytics/feedback'


const mapStateToProps = (state, props) =>
	({
		docs: state.feedback.docs,
		fetching: state.feedback.fetching,
		companies: state.companies.docs
	})

const mapDispatchToProps = dispatch =>
	({
		fetchFeedback(startDate, endDate, company_id) {
			dispatch(
				feedbackListener(startDate, endDate, company_id)
			)
		}
	})

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
