import React, {Component} from 'react'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import QuickSearchWithCompanyFilter from '../QuickSearchWithCompanyFilter'
import Collapse, {Panel} from '../Collapse'
import Form from './Form'
import '../../../assets/scss/Help/Help.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import LegalInfoFooter from "../LegalInfoFooter"


class Help extends Component {

  state = {
    query: "",
    companyFilter: {label: 'All Companies', value: 'all'}
  }

  handleQuickSearch = (query, companyFilter) => this.setState({
    query: query,
    companyFilter: companyFilter
  })

  handleUpdate = data => {
    this.props.setDoc(data)
  }

  handleDelete = data => this.props.deleteDoc(data)


  render() {
    const { query, companyFilter, addError } = this.state
    const { docs, companies, fetching } = this.props

    let filtered = docs

    if (!!companyFilter && companyFilter.value !== 'all') {
      filtered = filtered.filter(doc => doc.company_id === companyFilter.value)
    }

    if (query.length > 0) {
      filtered = filtered.filter(doc => JSON.stringify(doc).toLowerCase().includes(query))
    }

    return (
      <main className="app help">

        {fetching &&
          <ProgressBarIndeterminate />
        }

        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Help" />
          <QuickSearchWithCompanyFilter
            handleQuickSearch={this.handleQuickSearch}
            companies={companies}
            entries={filtered.length}
            linkTo="help/add"
            linkTitle="ADD HELP"
          />

          <Collapse accordion={true}>

            {filtered.map(doc =>
              <Panel header={doc.title} key={doc.id} headerClass="help-panel">
                <Form
                  key={doc.id}
                  companies={companies}
                  {...doc}
                  handleUpdate={this.handleUpdate}
                  handleDelete={this.handleDelete}
                  addError={addError}
                />
              </Panel>
            )}

          </Collapse>

          {filtered.length === 0 &&
            <p>No data</p>
          }

        </article>
        <LegalInfoFooter />

      </main>
    )
  }
}

export default Help