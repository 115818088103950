import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {addError} from "../error"
import moment from "moment-timezone"
import Timezone from "../../constants/timezone"
import _ from "lodash"


const isFetching = fetching =>
	({
		type: C.FEEDBACK_FETCHING_DOCS,
		payload: fetching
	})

const addDoc = doc =>
	({
		type: C.FEEDBACK_NEW_DOC,
		payload: doc
	})

const deleteDoc = doc =>
	({
		type: C.FEEDBACK_DELETE_DOC,
		payload: doc
	})

const updateDoc = doc =>
	({
		type: C.FEEDBACK_UPDATE_DOC,
		payload: doc
	})


export const feedbackListener  = (
	startDate = moment().subtract(6, 'd'),
	endDate = moment(),
	company_id = "all"
) => async (dispatch, getState) => {

	const {feedback: {docs = []}} = getState()

	dispatch(
		isFetching(true)
	)

	let query = await db.collection(COLLECTION.FEEDBACK)
		.where('date', '>=', startDate.tz(Timezone).startOf('day').toDate())
		.where('date', '<=', endDate.tz(Timezone).endOf('day').toDate())


	if(company_id !== 'all'){
		query = query.where('company_id', '==', company_id)
	}

	if(_.isFunction(window.feedbackDocListener)){
		window.feedbackDocListener()
		delete window.feedbackDocListener
	}

	window.feedbackDocListener = query
		.onSnapshot(querySnapshot => {

			dispatch(
				isFetching(false)
			)

			const queryDocs = querySnapshot.docs.map(i => ({id: i.id, ...i.data()}))

			docs.filter(i => !queryDocs.find( j => i.id === j.id  ))
				.forEach( i => dispatch( deleteDoc(i) ) )

			querySnapshot.docChanges().forEach(change => {

				const doc = {id: change.doc.id, ...change.doc.data() }
				doc.date_formatted = moment.unix(doc.date.toMillis() / 1000).tz(Timezone).format("YYYY/MM/DD (z)")

				console.log(`feedback: ${JSON.stringify(doc, null, 2)}`)

				switch (change.type) {
					case 'added':
						dispatch(
							addDoc(doc)
						)
						break
					case 'modified':
						dispatch(
							updateDoc(doc)
						)
						break
					case 'removed':
						dispatch(
							deleteDoc(doc)
						)
						break
					default:
						break
				}

			})
		}, error => {
			dispatch(
				isFetching(false)
			)
			dispatch(
				addError(error.message)
			)
		})
}