import Login from '../../ui/Login/Login'
import {connect} from 'react-redux'
import {signInWithPhoneNumber, signInWithConfirmationCode, setStep} from '../../../actions/login'


const mapStateToProps = (state, props) =>
  ({
    user: state.auth.user,
    fetching: state.auth.fetching,
    step: state.auth.step,
    errors: state.errors
  })

const mapDispatchToProps = dispatch =>
  ({
    signInWithPhoneNumber(admin_id) {
      dispatch(
        signInWithPhoneNumber(admin_id)
      )
    },
    signInWithConfirmationCode(code) {
      dispatch(
        signInWithConfirmationCode(code)
      )
    },
    setStep(step) {
      dispatch(
        setStep(step)
      )
    },
  })


export default connect(mapStateToProps, mapDispatchToProps)(Login)