import ACTION from '../../../constants/actionType'
import {combineReducers} from 'redux'
import _ from 'lodash'


const fetching = (state = false, action) =>
  (action.type === ACTION.RESOURCE_FETCHING_DOCS) ?
  action.payload :
  state


const docs = (state = [], action) => {

  let newDoc, docID, docIndex


  switch (action.type) {
    case ACTION.RESOURCE_NEW_DOC:
      newDoc = action.payload
      docID = newDoc.id
      docIndex = _.findIndex(state, doc => doc.id === docID)

      return docIndex < 0 ? _.orderBy([...state, newDoc], ['index'], ['asc']) : state
    case ACTION.RESOURCE_UPDATE_DOC:
      newDoc = action.payload
      docID = newDoc.id
      docIndex = _.findIndex(state, doc => doc.id === docID)

      return [
        ...state.slice(0, docIndex),
        newDoc,
        ...state.slice(docIndex + 1)
      ]
    case ACTION.RESOURCE_DELETE_DOC:
      newDoc = action.payload
      docID = newDoc.id
      return _.reject(state, doc => doc.id === docID)
    default:
      return state
  }

}


export default combineReducers({
  docs,
  fetching
})