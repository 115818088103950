import React from 'react'
import styled from "styled-components"

const KPIWrapper = styled.div`
  background-color: white;
  padding: 15px 0px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 180px;
`

const H3Wrapper = styled.h3`
    font-size: 16px;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    color: #A7A8AA;
    text-align: center;
    margin: 0;
    padding: 0;
`

const H2Wrapper = styled.h3`
    font-size: 20px;
    font-weight: bold;
    color: #14171A;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    margin: 0;
    padding: 5px 0;
    line-height: 1;
`

const KPI = ({ title, amount }) => {
  const numberWithCommas = num => num.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  return (
    <KPIWrapper>
      <H3Wrapper>{title}</H3Wrapper>
      <H2Wrapper>{numberWithCommas(amount)}</H2Wrapper>
    </KPIWrapper>
  )
}


export default KPI
