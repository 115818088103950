import Appointments from '../../ui/Appointments/Appointments'
import {connect} from 'react-redux'
import {
	addCompanyID,
	addDate,
	addQuery,
	appointmentListener,
	cancelAppointment,
	rescheduleAppointment,
	setAppointment
} from '../../../actions/appointments/index'


const mapStateToProps = (state, props) =>
	({
		companies: state.companies.docs,
		company_id: state.appointments.company_id,
		date: state.appointments.date,
		query: state.appointments.query,
		docs: state.appointments.docs,
		fetching: state.appointments.fetching
	})

const mapDispatchToProps = dispatch =>
	({
		appointmentListener(date) {
			dispatch(
				appointmentListener(date)
			)
		},
		cancelAppointment(doc) {
			dispatch(
				cancelAppointment(doc)
			)
		},
		setAppointment(hcp, patient, date) {
			dispatch(
				setAppointment(hcp, patient, date)
			)
		},
		rescheduleAppointment(appointment, date) {
			dispatch(
				rescheduleAppointment(appointment, date)
			)
		},
		addQuery(query) {
			dispatch(
				addQuery(query)
			)
		},
		addDate(date) {
			dispatch(
				addDate(date)
			)
		},
		addCompanyID(company_id) {
			dispatch(
				addCompanyID(company_id)
			)
		}
	})

export default connect(mapStateToProps, mapDispatchToProps)(Appointments)
