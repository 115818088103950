import C from '../../constants/actionType'
import {db, FieldValue, Timestamp} from '../index'
import {addError} from "../error"
import COLLECTION from '../../constants/collections'
import Role from "../../constants/Role"


const isFetching = fetching =>
	({
		type: C.PATIENTS_FETCHING_DOCS,
		payload: fetching
	})

export const addDoc = doc => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {
		const patient_id = doc.patient_id

		const querySnapshot = await db.collection(COLLECTION.PATIENTS)
			.where("patient_id", "==", patient_id)
			.get()

		if (!querySnapshot.empty) {
			throw new Error(`Patient with patient id: ${patient_id} already exists. Please use a unique id`)
		}

		const temp = {
			company_id: doc.company_id,
			password: doc.password,
			patient_id: doc.patient_id,
			registered: Timestamp.now(),
			online: {
				status: false,
				last_updated: Timestamp.now(),
			},
			is_auth: doc.is_auth,
			keywords: doc.keywords
		}

		const id = db.collection(COLLECTION.PATIENTS)
			.doc()
			.id + Role.PATIENT

		await db.collection(COLLECTION.PATIENTS)
			.doc(id)
			.set(temp)

		const increment = FieldValue.increment(1)
		await db.collection(COLLECTION.COUNTERS).doc(COLLECTION.PATIENTS).set({
			total: increment
		}, { merge: true })

	} catch (error) {
		dispatch(
			addError(error.message)
		)

		dispatch(
			isFetching(false)
		)
	}

}
