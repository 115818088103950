import React, {Component} from 'react'
import '../../../assets/scss/CompanyAdmins/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import ReactTooltip from 'react-tooltip'
import {Timestamp} from '../../../actions'
import Select from "react-select"
import getKeywords from "../../../util/getKeywords"


class Form extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: props.id || null,
      admin_id: props.admin_id || "",
      company_id: !!props.companies ? props.companies.map(i => ({ label: i.display_name, value: i.company_id })).find(company => company.value === props.company_id) : null,
      telephone: props.telephone || "",
      keywords: props.keywords || [],
      is_auth: props.is_auth || false,
      registered: props.registered || Timestamp.now
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleDelete = e => {
    e.preventDefault()
    this.props.handleDelete(this.state)
  }


  handleSubmit = e => {
    e.preventDefault()

    const {id, admin_id, company_id, telephone, is_auth, registered} = this.state

    if (!company_id) {
      this.props.addError("Please select a company id")
    } else if (admin_id.length === 0) {
      this.props.addError("Please enter an admin id")
    } else if (telephone.length === 0) {
      this.props.addError("Please enter a telephone")
    } else {
      const data = {
        id: id,
        admin_id: admin_id.toLowerCase().replace(/\s/g, ''),
        telephone: telephone.toLowerCase().replace(/\s/g, ''),
        is_auth: is_auth,
        registered: registered,
        company_id: company_id.value
      }

      data.keywords = Array.from(new Set([
        ...getKeywords(admin_id.toLowerCase())
      ]))

      this.props.handleUpdate(data)
    }
  }


  render() {
    const { admin_id, company_id, telephone } = this.state
    const { companies } = this.props

    const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

    return (
      <form
        className="edit-company-admin" onSubmit={this.handleSubmit}
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
      >

        <div className="company_id">

          <label data-tip={`The Company ID for the item`}>Company ID</label>

          <Select
            name="company_id"
            value={company_id}
            onChange={selected => this.setState({ company_id: selected })}
            options={options}
          />
        </div>

        <div className="admin_id">
          <label data-tip={`Admin ID`}>Admin ID</label>
          <input
            name="admin_id"
            type="text"
            placeholder="Please enter an Admin ID..."
            value={admin_id}
            onChange={this.handleChange}
            readOnly
          />
        </div>

        <div className="telephone">
          <label data-tip={`Telephone for the Admin ID`}>Telephone</label>
          <input
            name="telephone"
            type="text"
            placeholder="Please enter a telephone..."
            value={telephone}
            onChange={this.handleChange}
          />
        </div>

        <input
          className="trash"
          onClick={this.handleDelete}
          type="image" src={trashIcon}
          alt="Trash Icon"
          data-tip={`Click to delete record`}
        />

        <input
          className="update btn"
          type="submit"
          value="UPDATE"
          data-tip={`Click to update record`}
        />

        <ReactTooltip />

      </form>
    )
  }
}


export default Form