import styled from 'styled-components'

const BlueButton = styled.button`
    background-color: #00A9F4;
    color: white;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 25px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in;
    &:hover{
      background-color: #0097db;
    }
`

export default BlueButton