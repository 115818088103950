import styled from "styled-components"

const LabelWrapper = styled.label`
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	color: #14171A;
	margin-bottom: 4px;
`

export default LabelWrapper