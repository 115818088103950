import React from 'react'
import ReactTable from "react-table"
import "react-table/react-table.css"
import '../../../assets/scss/Calls/ReactTable.scss'
import matchSorter from 'match-sorter'
import moment from 'moment-timezone'
import Timezone from '../../../constants/timezone'


const CallsTable = ({ data = [] }) => {

  const numberOfRows = data.length > 25 ? 25 : data.length

  console.log(data)

  return (
    <ReactTable
      data={data}
      filterable
      defaultFilterMethod={(filter, row) =>
        String(row[filter.dateCreated]) === filter.value}
      columns={[
        {
          columns: [
            {
              Header: "SID",
              accessor: "sid",
              filterMethod: (filter, row) =>
                row[filter.sid].startsWith(filter.value) && row[filter.sid].endsWith(filter.value)
            }, {
              Header: "Date",
              id: "date_created",
              accessor: d => moment(d.date_created).tz(Timezone).format("YYYY-MM-DD HH:mma z"),
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["date_created"] }),
              filterAll: true
            }, {
              Header: "HCP ID",
              id: "hcp_id",
              accessor: d => d.hcp_id,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["hcp_id"] }),
              filterAll: true
            }, {
              Header: "HCP Call Duration",
              id: "hcp_duration",
              accessor: d => d.hcp_duration,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["hcp_duration"] }),
              filterAll: true
            }, {
              Header: "Patient ID",
              id: "patient_id",
              accessor: d => d.patient_id,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["patient_id"] }),
              filterAll: true
            }, {
              Header: "Patient Call Duration",
              id: "patient_duration",
              accessor: d => d.patient_duration,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["patient_duration"] }),
              filterAll: true
            }, {
              Header: "Cost of Call ($)",
              id: "cost_of_call",
              accessor: d => d.cost_of_call,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["cost_of_call"] }),
              filterAll: true
            }, {
              Header: "Call status",
              id: "call_failed",
              accessor: d => d.call_failed,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["call_failed"] }),
              filterAll: true
            }
          ]
        }
      ]}
      defaultSorted={[
        {
          id: "date_created",
          desc: false
        }
      ]}
      def
      defaultPageSize={numberOfRows}
      className="-striped -highlight"
    />
  )

}


export default CallsTable