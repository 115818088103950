import React, {Component} from 'react'
import Header from '../../container/Header'
import QuickSearchWithCompanyFilter from '../QuickSearchWithCompanyFilter'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import Collapse, {Panel} from '../Collapse'
import Form from './Form'
import '../../../assets/scss/Resources/Resources.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import LegalInfoFooter from "../LegalInfoFooter"


class Resources extends Component {

  state = {
    query: "",
    companyFilter: {label: 'All Companies', value: 'all'}
  }

  handleQuickSearch = (query, companyFilter) => this.setState({
    query: query,
    companyFilter: companyFilter
  })

  handleUpdate = data => this.props.setDoc(data)

  handleDelete = data => this.props.deleteDoc(data)

  render() {
    const { docs, companies, fetching } = this.props
    const { query, companyFilter } = this.state

    let filtered = docs

    if (!!companyFilter && companyFilter.value !== 'all') {
      filtered = filtered.filter(doc => doc.company_id === companyFilter.value)
    }

    if (query.length > 0) {
      filtered = filtered.filter(doc => JSON.stringify(doc).toLowerCase().includes(query))
    }

    return (
      <main className="app resources">

        {fetching &&
          <ProgressBarIndeterminate />
        }

        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Resources" />

          <QuickSearchWithCompanyFilter
            handleQuickSearch={this.handleQuickSearch}
            companies={companies}
            entries={filtered.length}
            linkTo="resources/add"
            linkTitle="ADD RESOURCE"
          />

          <Collapse accordion={true}>

            {filtered.map(doc =>
              <Panel header={doc.title} key={`${doc.id}-Panel`} headerClass="resource-panel">
                <Form
                  key={`${doc.id}-ResourceForm`}
                  {...doc}
                  companies={companies}
                  handleUpdate={this.handleUpdate}
                  handleDelete={this.handleDelete}
                />
              </Panel>
            )}

          </Collapse>

          {filtered.length === 0 &&
            <p>No data</p>
          }

        </article>
        <LegalInfoFooter />
      </main>
    )
  }
}

export default Resources