const config = {
  apiKey: "AIzaSyAYyzpyp7T7DVNq6q7WdpeUxuOcGaOQhEc",
  authDomain: "nurse-connect-49b14.firebaseapp.com",
  databaseURL: "https://nurse-connect-49b14.firebaseio.com",
  projectId: "nurse-connect-49b14",
  storageBucket: "nurse-connect-49b14.appspot.com",
  messagingSenderId: "866351873116",
  appId: "1:866351873116:web:981cdf3e16320c28687a99",
  measurementId: "G-QXV2F6M61Z"
}


export default config