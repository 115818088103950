import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import '../../../assets/scss/Companies/LogoDropzone.scss'
import {storageRef} from '../../../actions'
import moment from 'moment-timezone'
import ProgressBarDeterminate from "../ProgressBar/ProgressBarDeterminate";


const LogoDropzone = props => {
  const { getImage } = props
  const [isUploadActive, setUploadActive] = useState(false)
  const [progress, setProgress] = useState(0)

  const getExt = filename => {
    const idx = filename.lastIndexOf('.')
    return (idx < 1) ? "" : filename.substr(idx + 1)
  }

  const onDrop = useCallback(acceptedFiles => {
    setUploadActive(true)

    const file = acceptedFiles.shift()
    const fileName = file.name.replace(/\s+/g, '-').toLowerCase()

    const imageRef = `companies/${moment().format('YYYY-MM-DD-HH-mm')}-${fileName}`

    const uploadTask = storageRef.child(imageRef).put(file)

    uploadTask.on(
      'state_changed',
      snapshot => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      },
      error => {
        this.addError(error)
        setUploadActive(false)
      },
      () => {
        setUploadActive(false)
        getImage(imageRef)
      }
    )
  }, [getImage])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      {
        !isUploadActive &&
        <div className="LogoDropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <>
                <p>Drop files to upload</p>
                <p>(Please only upload .png with dimensions of 512x512)</p>
                <p>or</p>
                <button type="button">Select Files</button>
              </>
          }
        </div>
      }
      {
        isUploadActive &&
        <ProgressBarDeterminate progress={progress} />
      }
    </>
  )
}


export default LogoDropzone