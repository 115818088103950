const getKeywords = word => {
	const keywords = []

	if (word.length > 2) {
		for (let i = 1; i <= word.length; i++) {
			keywords.push(word.substring(0, i))
		}
	}

	return keywords
}

export default getKeywords