import HealthcareProfessionals from '../../ui/HealthcareProfessionals'
import {connect} from 'react-redux'
import {deleteDoc, setDoc} from '../../../actions/healthcare_professionals'
import {addError} from '../../../actions/error'


const mapStateToProps = (state, props) =>
	({
		companies: state.companies.docs,
		docs: state.healthcare_professionals.docs,
		fetching: state.healthcare_professionals.fetching
	})

const mapDispatchToProps = dispatch =>
	({
		deleteDoc(doc) {
			dispatch(
				deleteDoc(doc)
			)
		},
		setDoc(doc) {
			dispatch(
				setDoc(doc)
			)
		},
		addError(error) {
			dispatch(
				addError(error)
			)
		}
	})

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareProfessionals)
