import ACTION from '../../constants/actionType'
import COLL from '../../constants/collections'
import {db} from '../index'
import {addError} from "../error"
import moment from "moment"


const isFetching = fetching =>
  ({
    type: ACTION.TWILIO_ROOMS_FETCHING_DOCS,
    payload: fetching
  })

const add = doc =>
  ({
    type: ACTION.TWILIO_ROOMS_NEW_DOC,
    payload: doc
  })

const clear = () =>
  ({
    type: ACTION.TWILIO_ROOMS_CLEAR_DOCS,
    payload: []
  })


export const fetchTwilioRooms = (
  startDate = moment().subtract(7, 'd'),
  endDate = moment(),
  company_id = "all"
) => async (dispatch, getState) => {

  try {

    console.log(`startDate: ${startDate.toDate()}`)
    console.log(`endDate: ${endDate.toDate()}`)

    dispatch(
      isFetching(true)
    )

    dispatch(
      clear()
    )

    const query = db.collection(COLL.TWILIO_ROOMS)
      .where('dateCreated', '>=', startDate.startOf('day').toDate())
      .where('dateCreated', '<=', endDate.endOf('day').toDate())

    const snapshot = company_id !== 'all' ?
      await query.where('appointment.company_id', '==', company_id).get() :
      await query.get()

    if (!snapshot.empty) {

      snapshot.docs.forEach(snap => {
        const doc = {
          id: snap.id,
          ...snap.data()
        }
        dispatch(
          add(doc)
        )
      })

    }

  } catch (error) {
    dispatch(
      clear()
    )
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}
