import React, {Component} from 'react'
import '../../../assets/scss/Help/HelpForm.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import Checkbox from './Checkbox'
import ReactTooltip from 'react-tooltip'
import getKeywords from "../../../util/getKeywords"
import roleType from '../../../constants/roleType'
import isUrlValid from '../../../util/isUrlValid'
import Select from 'react-select'
import InputNumber from 'rc-input-number'
import 'rc-input-number/assets/index.css'


class Form extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: props.id || null,
      company_id: !!props.companies ? props.companies.map(i => ({ label: i.display_name, value: i.company_id })).find(company => company.value === props.company_id) : null,
      title: props.title || "",
      index: props.index || 0,
      body: props.body || "",
      linkTitle: props.linkTitle || null,
      linkURL: props.linkURL || null,
      type: roleType.find(role => role.value === props.type),
      keywords: props.keywords || [],
      withLink: props.linkTitle?.length > 0 || props.linkURL?.length > 0
    }

  }

  handleChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (target.type === 'checkbox' && !value) {
      this.setState({
        linkTitle: null,
        linkURL: null
      })
    }

    this.setState({
      [name]: value
    })
  }

  handleDelete = e => {
    e.preventDefault()
    this.props.handleDelete(this.state)
  }

  handleReset = e => {
    e.preventDefault()
    this.setState({
      [e.target.dataset.field]: ""
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { id, company_id, title, body, linkTitle, linkURL, type, index, withLink } = this.state

    if (!company_id) {
      this.props.addError("Please select a company id")
    } else if (title.length === 0) {
      this.props.addError("Please enter a title")
    } else if (body.length === 0) {
      this.props.addError("Please enter a body")
    } else if (withLink && !!linkTitle && linkTitle.length === 0) {
      this.props.addError("Please a a link title")
    } else if (withLink && !!linkURL && !isUrlValid(linkURL)) {
      this.props.addError("Please a valid link url")
    } else {
      const data = {
        id: id,
        company_id: company_id.value,
        title: title,
        body: body,
        type: type.value,
        index: `${index}`,
        withLink: withLink
      }

      if (withLink) {
        data.linkURL = linkURL
        data.linkTitle = linkTitle
      }

      data.keywords = Array.from(new Set([
        ...getKeywords(title.toLowerCase()),
        ...getKeywords(body.toLowerCase())
      ]))

      this.props.handleUpdate(data)
    }

  }

  render() {

    const { company_id, title, body, linkTitle, linkURL, type, index, withLink } = this.state
    const { companies } = this.props

    const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []


    return (
      <form
        className="edit-help"
        onSubmit={this.handleSubmit}
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
      >

        <div className="company_id">
          <label data-tip={`The title for the item`}>Company ID</label>
          <Select
            name="company_id"
            value={company_id}
            onChange={selected => this.setState({ company_id: selected })}
            options={[ { label: 'All', value: 'all' }, ...options]}
          />
        </div>

        <div className="title">
          <label data-tip={`The title for the item`}>Title</label>
          <input
            tabIndex="10"
            name="title"
            type="text"
            placeholder="title"
            value={title}
            onChange={this.handleChange}
          />
        </div>

        <div className="type">
          <label data-tip={`The group for the item`}>Group</label>
          <Select
            name="type"
            value={type}
            onChange={selected => this.setState({ type: selected })}
            options={roleType}
          />
        </div>

        <Checkbox
          title="Link"
          name="withLink"
          withLink={withLink}
          handleChange={this.handleChange}
        />

        {this.state.withLink &&
          <>
            <div className="linkTitle">
              <label data-tip={`The link title`}>Link title</label>
              <input
                tabIndex="30"
                name="linkTitle"
                type="text"
                placeholder="link title"
                value={linkTitle}
                onChange={this.handleChange}
              />
            </div>

            <div className="linkURL">
              <label data-tip={`The link url`}>Link Url</label>
              <input
                tabIndex="40"
                name="linkURL"
                type="text"
                placeholder="link url"
                value={linkURL}
                onChange={this.handleChange}
              />
            </div>
          </>
        }

        <div className="body">
          <label data-tip={`The description for the item`}>Description (500 word limit)</label>
          <textarea
            tabIndex="50"
            name="body"
            value={body}
            onChange={this.handleChange}
          />
        </div>

        <div className="index">
          <label data-tip={`The rank order for the item`}>Order</label>
          <InputNumber
            name="index"
            min={0}
            value={index}
            defaultValue={0}
            onChange={ v => this.setState({index: v})}
          />
        </div>

        <input
          className="trash"
          onClick={this.handleDelete}
          type="image"
          alt="delete item"
          src={trashIcon}
          data-tip={`Click to delete record`}
        />

        <input
          data-tip={`Click to update record`}
          className="update btn"
          type="submit"
          value="UPDATE"
        />

        <ReactTooltip />

      </form>
    )
  }
}

export default Form