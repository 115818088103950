import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import getKeywords from '../../../util/getKeywords'
import LogoDropzone from './LogoDropzone'
import close from '../../../assets/svg/close.svg'
import trashIcon from '../../../assets/svg/trash.svg'
import '../../../assets/scss/Companies/Add.scss'
import ReactTooltip from 'react-tooltip'
import {storageRef, Timestamp} from '../../../actions'
import Close from '../Close'
import ButtonCloseWrapper from "../ButtonWithCloseIcon"
import LegalInfoFooter from "../LegalInfoFooter"


class Add extends Component {

  state = {
    company_id: "",
    display_name: "",
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    link: "",
    registered: Timestamp.now(),
    logo: "",
    image_ref: "",
    keywords: []
  }

  getImage = async image_ref => {

    this.setState({
      image_ref: image_ref
    })

    try {
      const url = await storageRef.child(image_ref).getDownloadURL()

      this.setState({
        url: url
      })

    } catch (error) {
      console.log(error)
    }
  }

  addError = error => {
    this.props.addError(error)
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })


  handleSubmit = e => {
    e.preventDefault()

    const {
      company_id, display_name, image_ref, title, first_name, last_name, email, link, registered } = this.state

    if (company_id.length === 0) {
      this.props.addError("Please provide a Company ID")
    } else if (display_name.length === 0) {
      this.props.addError("Please provide a Company Display Name")
    } else {

      const data = {
        company_id: company_id.toLowerCase().replace(/\s/g, ''),
        display_name: display_name,
        registered: registered
      }

      if (title.length > 0) {
        data.title = title
      }

      if (first_name.length > 0) {
        data.first_name = first_name
      }

      if (last_name.length > 0) {
        data.last_name = last_name
      }

      if (email.length > 0) {
        data.email = email
      }

      if (link.length > 0) {
        data.link = link
      }

      if (image_ref.length > 0) {
        data.image_ref = image_ref
      }

      data.keywords = Array.from(new Set([
        ...getKeywords(first_name.toLowerCase()),
        ...getKeywords(last_name.toLowerCase()),
        ...getKeywords(email.toLowerCase()),
        ...getKeywords(link.toLowerCase()),
        ...getKeywords(company_id.toLowerCase()),
        ...getKeywords(display_name.toLowerCase())
      ]))

      this.props.addDoc(data)
      this.props.history.push('/companies')
    }

  }

  handleFullReset = e => {
    e.preventDefault()
    this.setState({
      company_id: "",
      display_name: "",
      title: "",
      first_name: "",
      last_name: "",
      email: "",
      link: "",
      registered: Timestamp.now(),
      logo: "",
      image_ref: "",
      keywords: []
    })
  }

  handleFieldReset = e => {
    e.preventDefault()
    const field = e.currentTarget.dataset.field ?? ""
    if(!!field){
      this.setState({
        [field]: ""
      })
    }
  }

  render() {
    const { url, display_name, company_id, image_ref, title, first_name, last_name, email, link } = this.state

    return (
      <main className="app add-company">
        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Add Company" />

          <section className="content">

            <h3>New Company</h3>

            <Link
              to="/companies"
              className="close"
              data-tip={`Click to close`}>
              <img src={close} alt="close" />
            </Link>

            <form
              className="add-company-form"
              onSubmit={this.handleSubmit}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
            >

              <div className="company_id">
                <label data-tip={`The company id for the item`}>Company ID</label>
                <input
                  tabIndex="10"
                  name="company_id"
                  type="text"
                  placeholder="Please enter a Company ID..."
                  value={company_id}
                  onChange={this.handleChange}
                />
                {company_id.length > 0 &&
                <ButtonCloseWrapper
                  data-field="company_id"
                  onClick={this.handleFieldReset}
                  type="reset"
                />
                }
              </div>

              <div className="display_name">
                <label data-tip={`The company name to display`}>Display Name</label>
                <input
                  tabIndex="10"
                  name="display_name"
                  type="text"
                  placeholder="Please enter a company name"
                  value={display_name}
                  onChange={this.handleChange}
                />
                {display_name.length > 0 &&
                <ButtonCloseWrapper
                  data-field="display_name"
                  onClick={this.handleFieldReset}
                  type="reset"
                />
                }
              </div>

              <div className="title">
                <label data-tip={`The contact's title`}>Title</label>
                <input
                  tabIndex="10"
                  name="title"
                  type="text"
                  placeholder="Please enter the title..."
                  value={title}
                  onChange={this.handleChange}
                />
                {title.length > 0 &&
                <ButtonCloseWrapper
                  data-field="title"
                  onClick={this.handleFieldReset}
                  type="reset"
                />
                }
              </div>

              <div className="first_name">
                <label data-tip={`The contact's first name`}>First Name</label>
                <input
                  tabIndex="10"
                  name="first_name"
                  type="text"
                  placeholder="Please enter the first name..."
                  value={first_name}
                  onChange={this.handleChange}
                />
                {first_name.length > 0 &&
                <ButtonCloseWrapper
                  data-field="first_name"
                  onClick={this.handleFieldReset}
                  type="reset"
                />
                }
              </div>

              <div className="last_name">
                <label data-tip={`The contact's last name`}>Last Name</label>
                <input
                  tabIndex="10"
                  name="last_name"
                  type="text"
                  placeholder="Please enter the last name..."
                  value={last_name}
                  onChange={this.handleChange}
                />
                {last_name.length > 0 &&
                <ButtonCloseWrapper
                  data-field="last_name"
                  onClick={this.handleFieldReset}
                  type="reset"
                />
                }
              </div>

              <div className="email">
                <label data-tip={`The contact's email`}>Email</label>
                <input
                  tabIndex="10"
                  name="email"
                  type="text"
                  placeholder="Please enter the email..."
                  value={email}
                  onChange={this.handleChange}
                />
                {email.length > 0 &&
                <ButtonCloseWrapper
                  data-field="email"
                  onClick={this.handleFieldReset}
                  type="reset"
                />
                }
              </div>

              <div className="link">
                <label data-tip={`The web address link`}>Web Address Link</label>
                <input
                  tabIndex="10"
                  name="link"
                  type="text"
                  placeholder="Web Address Link..."
                  value={link}
                  onChange={this.handleChange}
                />
                {link.length > 0 &&
                <ButtonCloseWrapper
                  data-field="link"
                  onClick={this.handleFieldReset}
                  type="reset"
                />
                }
              </div>

              <div className="logo">
                <label data-tip={`The company logo`}>Logo</label>
                {image_ref.length > 0 ? (
                  <figure>
                    <img alt="company logo" src={url} loading="lazy" />
                    <Close onClick={() => { this.setState({ image_ref: "" }) }} />
                  </figure>
                ) : (
                  <LogoDropzone
                    getImage={this.getImage}
                  />
                )}
              </div>

              <input
                className="reset"
                onClick={this.handleFullReset}
                type="image"
                alt=""
                src={trashIcon}
                data-tip={`Click to reset record`}
              />

              <input
                data-tip={`Click to add record`}
                className="add btn"
                type="submit"
                value="ADD COMPANY"
              />

              <ReactTooltip />

            </form>

          </section>
        </article>
        <LegalInfoFooter />
      </main>
    )
  }
}

export default Add