import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db, FieldValue} from '../index'
import {addError} from "../error"


const isFetching = fetching =>
  ({
    type: ACTION.HELP_FETCHING_DOCS,
    payload: fetching
  })

const remove = doc =>
  ({
    type: ACTION.HELP_DELETE_DOC,
    payload: doc
  })

const update = doc =>
  ({
    type: ACTION.HELP_UPDATE_DOC,
    payload: doc
  })

export const deleteDoc = doc => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    await db.collection(COLLECTION.HELP)
      .doc(doc.id)
      .delete()

    const increment = FieldValue.increment(-1)
    await db.collection(COLLECTION.COUNTERS)
      .doc(COLLECTION.HELP)
      .update({
        total: increment
      })

    dispatch(
      remove(doc)
    )

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}

export const setDoc = doc => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    const docID = doc.id
    const data = {
      company_id: doc.company_id,
      title: doc.title,
      body: doc.body,
      index: doc.index,
      type: doc.type,
      withLink: doc.withLink,
      keywords: doc.keywords
    }

    if (doc.withLink) {
      data.linkTitle = doc.linkTitle
      data.linkURL = doc.linkURL
    }

    await db.collection(COLLECTION.HELP).doc(docID).set(data)

    dispatch(
      update(doc)
    )

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}