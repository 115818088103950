const roleType = [{
  label: "All",
  value: "all"
}, {
  label: "Patients",
  value: "patient"
}, {
  label: "Health Care Professionals",
  value: "health-care-professional"
}]

export default roleType