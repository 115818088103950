import React from 'react'
import '../../assets/scss/EntryHeader.scss'


const EntryHeader = props => (
	<header className="entry-header">
		<h1 className="entry-title">{props.title}</h1>
	</header>
)

export default EntryHeader
