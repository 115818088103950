import React from 'react'
import {Link, Route} from 'react-router-dom'


const NavItem = ({ children, to, exact }) =>
  <Route path={to} exact={exact} children={({ match }) => (
    <li className={match ? 'active' : null}>
      <Link to={to}>{children}</Link>
    </li>
  )} />

export default NavItem
