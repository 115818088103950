import React, {useEffect} from 'react'
import {Redirect, Route} from "react-router-dom"


const PrivateRoute = ({ component: Component, user, dataListeners, ...rest }) => {

  useEffect(() => {
    if(!!user){
      dataListeners()
    }
  }, [user])

  return (
    <Route {...rest} render={ props =>
      user !== null
        ? (<Component {...props} />)
        : (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
    } />
  )
}

export default PrivateRoute