import Header from '../ui/Header'
import {connect} from 'react-redux'
import {signOut} from '../../actions/header'


const mapStateToProps = (state, props) =>
  ({})

const mapDispatchToProps = dispatch =>
  ({
    signOut() {
      dispatch(
        signOut()
      )
    }
  })

export default connect(mapStateToProps, mapDispatchToProps)(Header)