import appReducer from './reducers'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import {applyMiddleware, createStore} from 'redux'

const persistConfig = {
  key: 'care-connect-call-super-admin-v1',
  storage,
  stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, appReducer)


export const configureStore = () =>
  createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(thunk)
    )
  )