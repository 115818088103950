import styled from "styled-components"
import React from "react"

const FooterWrapper = styled.footer`
	max-width: 90%;
	margin: 64px auto 16px auto;
	
	>p{
		font-size: 14px;
		color: #d3d3d3;
		margin: 0 0 8px 0;
		padding: 0;
		line-height: 1.2;
		transition: all 0.3 ease-in;
		> a{
				font-size: 14px;
				color: #d3d3d3;
				text-decoration: underline;
		}
	}
	
	&:hover * {
		color: #676767;
	}
`

const LegalInfoFooter = props => (
	<FooterWrapper>
		<p>By using this site you agree not to copy, distribute, display, disseminate, or otherwise reproduce any of the information on the Site without receiving our prior written permission.</p>
		<p>Registered and incorporated in Scotland</p>
		<p>Company number SC447227</p>
		<p>All rights reserved © 2020 Talking Medicines Limited</p>
		<p>Contact us: <a href="mailto:info@careconnectcall.com">info@careconnectcall.com</a></p>
	</FooterWrapper>
)


export default LegalInfoFooter