const constants = {

  AUTH_LOGIN: "AUTH/LOGIN",
  AUTH_LOGOUT: "AUTH/LOGOUT",
  AUTH_FETCHING: "AUTH/FETCHING",
  AUTH_STEP: "AUTH/STEP",

  SUPER_ADMIN_NEW_DOC: "SUPER_ADMIN/NEW_DOC",
  SUPER_ADMIN_UPDATE_DOC: "SUPER_ADMIN/UPDATE_DOC",
  SUPER_ADMIN_DELETE_DOC: "SUPER_ADMIN/DELETE_DOC",
  SUPER_ADMIN_FETCHING_DOCS: "SUPER_ADMIN/FETCHING",

  COMPANY_ADMIN_NEW_DOC: "COMPANY_ADMIN/NEW_DOC",
  COMPANY_ADMIN_UPDATE_DOC: "COMPANY_ADMIN/UPDATE_DOC",
  COMPANY_ADMIN_DELETE_DOC: "COMPANY_ADMIN/DELETE_DOC",
  COMPANY_ADMIN_FETCHING_DOCS: "COMPANY_ADMIN/FETCHING",

  COMPANY_NEW_DOC: "COMPANY/NEW_DOC",
  COMPANY_UPDATE_DOC: "COMPANY/UPDATE_DOC",
  COMPANY_DELETE_DOC: "COMPANY/DELETE_DOC",
  COMPANY_FETCHING_DOCS: "COMPANY/FETCHING",

  APPOINTMENT_NEW_DOC: "APPOINTMENT/NEW_DOC",
  APPOINTMENT_UPDATE_DOC: "APPOINTMENT/UPDATE_DOC",
  APPOINTMENT_DELETE_DOC: "APPOINTMENT/DELETE_DOC",
  APPOINTMENT_FETCHING_DOCS: "APPOINTMENT/FETCHING",
  APPOINTMENT_QUERY_TERM: "APPOINTMENT/QUERY/TERM",
  APPOINTMENT_QUERY_DATE_RANGE: "APPOINTMENT/QUERY/DATE_RANGE",
  APPOINTMENT_QUERY_COMPANY_ID: "APPOINTMENT/QUERY/COMPANY_ID",

  PATIENTS_NEW_DOC: "PATIENTS/NEW_DOC",
  PATIENTS_NEW_DOCS: "PATIENTS/NEW_DOCS",
  PATIENTS_UPDATE_DOC: "PATIENTS/UPDATE_DOC",
  PATIENTS_DELETE_DOC: "PATIENTS/DELETE_DOC",
  PATIENTS_DELETE_DOCS: "PATIENTS/DELETE_DOCS",
  PATIENTS_FETCHING_DOCS: "PATIENTS/FETCHING",
  PATIENTS_CLEAR_DOCS: "PATIENTS/CLEAR_DOCS",
  PATIENTS_QUERY: "PATIENTS/QUERY",
  PATIENTS_COMPANY_FILTER: "PATIENTS/FILTER/COMPANY",
  PATIENTS_PAGINATION_NEXT_AVAILABLE: "PATIENTS/PAGINATION/NEXT",
  PATIENTS_PAGINATION_PREVIOUS_AVAILABLE: "PATIENTS/PAGINATION/PREVIOUS",

  HEALTHCARE_PROFESSIONALS_NEW_DOC: "HEALTHCARE_PROFESSIONALS/NEW_DOC",
  HEALTHCARE_PROFESSIONALS_UPDATE_DOC: "HEALTHCARE_PROFESSIONALS/UPDATE_DOC",
  HEALTHCARE_PROFESSIONALS_DELETE_DOC: "HEALTHCARE_PROFESSIONALS/DELETE_DOC",
  HEALTHCARE_PROFESSIONALS_CLEAR_DOCS: "HEALTHCARE_PROFESSIONALS/CLEAR",
  HEALTHCARE_PROFESSIONALS_LAST_VISIBLE_DOC: "HEALTHCARE_PROFESSIONALS/LAST_VISIBLE_DOC",
  HEALTHCARE_PROFESSIONALS_FETCHING_DOCS: "HEALTHCARE_PROFESSIONALS/FETCHING",

  FEEDBACK_NEW_DOC: "FEEDBACK/NEW_DOC",
  FEEDBACK_UPDATE_DOC: "FEEDBACK/UPDATE_DOC",
  FEEDBACK_DELETE_DOC: "FEEDBACK/DELETE_DOC",
  FEEDBACK_FETCHING_DOCS: "FEEDBACK/FETCHING",
  FEEDBACK_CLEAR_DOCS: "FEEDBACK/CLEAR",

  TWILIO_ROOMS_NEW_DOC: "TWILIO_ROOMS/NEW/DOC",
  TWILIO_ROOMS_UPDATE_DOC: "TWILIO_ROOMS/UPDATE/DOC",
  TWILIO_ROOMS_DELETE_DOC: "TWILIO_ROOMS/DELETE/DOC",
  TWILIO_ROOMS_CLEAR_DOCS: "TWILIO_ROOMS/CLEAR",
  TWILIO_ROOMS_FETCHING_DOCS: "TWILIO_ROOMS/FETCHING",

  RESOURCE_NEW_DOC: "RESOURCE/NEW_DOC",
  RESOURCE_UPDATE_DOC: "RESOURCE/UPDATE_DOC",
  RESOURCE_DELETE_DOC: "RESOURCE/DELETE_DOC",
  RESOURCE_FETCHING_DOCS: "RESOURCE/FETCHING",

  HELP_NEW_DOC: "HELP/NEW_DOC",
  HELP_UPDATE_DOC: "HELP/UPDATE_DOC",
  HELP_DELETE_DOC: "HELP/DELETE_DOC",
  HELP_FETCHING_DOCS: "HELP/FETCHING",

  ERROR_NEW_DOC: "ERROR/NEW_DOC",
  ERROR_DELETE_DOC: "ERROR/DELETE/DOC"

}


export default constants