import React, {Component} from 'react'
import {Timestamp} from '../../../actions'
import '../../../assets/scss/HealthcareProfessionals/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import ReactTooltip from 'react-tooltip'
import Select from "react-select";
import getKeywords from "../../../util/getKeywords"


class Form extends Component {

	constructor(props) {
		super(props)

		this.state = {
			id: props.id || null,
			company_id: !!props.companies ? props.companies.map(i => ({ label: i.display_name, value: i.company_id })).find(company => company.value === props.company_id) : null,
			hcp_id: props.hcp_id || "",
			password: props.password || "",
			online: {
				status: props.status || false,
				last_updated: props.last_updated || Timestamp.now()
			},
			is_auth: props.is_auth || false,
			registered: props.registered || Timestamp.now()
		}

		if(!!props.fcm_token){
			this.state.fcm_token = props.fcm_token
		}

		if(!!props.pushkit_token){
			this.state.pushkit_token = props.pushkit_token
		}

	}

	handleChange = e => this.setState({[e.target.name]: e.target.value})

	handleDelete = e => {
		e.preventDefault()
		this.props.handleDelete(this.state)
	}

	handleSubmit = e => {
		e.preventDefault()

		const {
			id,
			company_id,
			hcp_id,
			password,
			online,
			is_auth = false,
			fcm_token = null,
			pushkit_token = null,
			registered = Timestamp.now()
		} = this.state

		if (hcp_id.length === 0) {
			this.props.addError("Please enter a healthcare professional id")
		} else if (password.length === 0) {
			this.props.addError("Please enter a password")
		} else {
			const data = {
				id: id,
				company_id: company_id.value,
				hcp_id: hcp_id.toLowerCase().replace(/\s/g, ''),
				password: password.toLowerCase().replace(/\s/g, ''),
				online: online,
				is_auth: is_auth,
				registered: registered
			}

			if (!!fcm_token) {
				data.fcm_token = fcm_token
			}

			if (!!pushkit_token) {
				data.pushkit_token = pushkit_token
			}


			data.keywords = Array.from(new Set([
				...getKeywords(hcp_id.toLowerCase())
			]))


			this.props.handleUpdate(data)
		}
	}


	render() {
		const {company_id, hcp_id, password} = this.state
		const { companies } = this.props

		const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

		return (
			<form
				className="edit-healthcare-professional"
				onSubmit={this.handleSubmit}
				onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
			>

				<div className="company_id">

					<label data-tip={`The Company ID for the item`}>Company ID</label>

					<Select
						name="company_id"
						value={company_id}
						onChange={selected => this.setState({ company_id: selected })}
						options={[ { label: 'All', value: 'all' }, ...options]}
						menuContainerStyle={{'zIndex': 999}}
					/>
				</div>

				<div className="hcp_id">
					<label data-tip={`Healthcare Professional ID`}>Healthcare Professional ID</label>
					<input
						name="hcp_id"
						type="text"
						placeholder="Healthcare Professional ID..."
						value={hcp_id}
						onChange={this.handleChange}
						readOnly
					/>
				</div>

				<div className="password">
					<label data-tip={`Password for HCP ID`}>Password</label>
					<input
						name="password"
						type="text"
						placeholder="password"
						value={password}
						onChange={this.handleChange}
					/>
				</div>

				<input
					className="trash"
					onClick={this.handleDelete}
					alt="delete item"
					type="image" src={trashIcon}
					data-tip={`Click to delete record`}
				/>

				<input
					className="update btn"
					type="submit"
					value="UPDATE"
					data-tip={`Click to update record`}
				/>

				<ReactTooltip/>

			</form>
		)
	}
}

export default Form