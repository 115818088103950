import React, {Component} from 'react'
import '../../../assets/scss/Checkbox.scss'


class Checkbox extends Component {

  handleInputChange = event => {
    this.props.handleChange(event)
  }

  render() {
    return (
      <label className="checkbox">
        <input
          type="checkbox"
          name={this.props.name}
          checked={this.props.withLink}
          onChange={this.handleInputChange}
        />
        <span data-tip={`Does this item include a link`}>{this.props.title}</span>
      </label>
    )
  }
}


export default Checkbox
