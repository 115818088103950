import React, {Component} from 'react'
import {Redirect} from "react-router-dom"
import ShowErrors from '../../container/ShowErrors'
import logo from '../../../assets/svg/logo.svg'
import '../../../assets/scss/Login/Login.scss'
import ProgressBarInDeterminate from "../ProgressBar/ProgressBarIndeterminate"
import {RecaptchaVerifier} from "../../../actions"


class Login extends Component {

  state = {
    admin_id: "",
    code: "",
    recaptchaWrapperRef: null
  }

  componentDidMount() {
    this.props.setStep(1)
    this.setUpRecaptchaVerifier()
  }

  setUpRecaptchaVerifier(){
    setTimeout(() => {
      window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
        'size': 'invisible',
        'callback': response => {
          this.props.signInWithPhoneNumber(this.state.admin_id)
        },
        'expired-callback': () => {
          this.setState({isButtonDisabled: true})
        }
      })

      window.recaptchaVerifier.render().then(widgetId => {
        window.recaptchaWidgetId = widgetId
      })
    },250)
  }

  componentDidUpdate(prevProps) {
    const {errors, step} =  this.props
    if (
      prevProps.errors !== errors &&
      step === 1 &&
      errors.includes("Error: Admin ID not found")
    ) {
      window.recaptchaVerifier.clear()

      this.recaptchaWrapperRef.innerHTML = `<button
                      id="recaptcha-container"
                      type="submit"
                      tabIndex="30">
                      GET SMS VERIFICATION CODE
                    </button>`

      this.setUpRecaptchaVerifier()
    }
  }

  componentWillUnmount(){
    delete window.recaptchaVerifier
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = e => {
    e.preventDefault()

    const { code } = this.state
    if (code.length > 0) {
      this.props.signInWithConfirmationCode(code)
    }
  }


  render() {
    const {fetching, user, step} = this.props
    const {admin_id, code} = this.state


    if (user != null) {
      return <Redirect to="/admins" />
    }

    return (
      <>
        {fetching &&
        <ProgressBarInDeterminate />
        }
        <ShowErrors />
        <section id="login">
          <div className="inner-content">
            <header className="App-header">
              <img src={logo} className="logo" alt="logo" />
            </header>

              <form
                onSubmit={this.handleSubmit}>

                { (step === 1) &&
                <>
                  <input
                    name="admin_id"
                    type="text"
                    placeholder="Please enter your admin id..."
                    tabIndex="10"
                    value={admin_id}
                    onChange={this.handleChange}
                  />
                  <div className={(fetching ? 'sync' : '')} ref={ref => this.recaptchaWrapperRef = ref}>
                    <button
                      id="recaptcha-container"
                      type="submit"
                      tabIndex="30"
                      >
                      GET SMS VERIFICATION CODE
                    </button>
                  </div>
                </>
                }

                { step === 2 &&
                <>
                  <input
                    name="code"
                    type="text"
                    placeholder="Please enter your confirmation code..."
                    tabIndex="20"
                    value={code}
                    onChange={this.handleChange}
                  />

                  <button
                    type="submit"
                    tabIndex="30"
                    className={(fetching ? 'sync' : '')}>
                    LOGIN
                  </button>
                </>
                }

              </form>

          </div>
        </section>
      </>
    )
  }
}


export default Login
