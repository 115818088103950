import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db, FieldValue, Timestamp} from '../index'
import {addError} from "../error"

// TODO: Make sure the additional items from the ticket are included

const isFetching = fetching =>
  ({
    type: ACTION.COMPANY_FETCHING_DOCS,
    payload: fetching
  })

const remove = doc =>
  ({
    type: ACTION.COMPANY_DELETE_DOC,
    payload: doc
  })

const update = doc =>
  ({
    type: ACTION.COMPANY_UPDATE_DOC,
    payload: doc
  })

export const deleteDoc = doc => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    await db.collection(COLLECTION.COMPANIES)
      .doc(doc.id)
      .delete()

    const increment = FieldValue.increment(-1)
    await db.collection(COLLECTION.COUNTERS)
      .doc(COLLECTION.COMPANIES)
      .update({
        total: increment
      })

    dispatch(
      remove(doc)
    )

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}

export const setDoc = ({
  id = "",
  company_id = "",
  display_name = "",
  title = "",
  first_name = "",
  last_name = "",
  email = "",
  link = "",
  registered = Timestamp.now(),
  keywords = [],
  image_ref = ""
}) => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    const temp = {
      company_id: company_id,
      display_name: display_name,
      registered: registered,
      keywords: keywords
    }

    if(title.length > 0) {
      temp.title = title
    }

    if(first_name.length > 0) {
      temp.first_name = first_name
    }

    if(last_name.length > 0) {
      temp.last_name = last_name
    }

    if(email.length > 0) {
      temp.email = email
    }

    if(link.length > 0) {
      temp.link = link
    }

    if (image_ref.length > 0) {
      temp.image_ref = image_ref
    }

    await db.collection(COLLECTION.COMPANIES)
      .doc(id)
      .set(temp)

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}