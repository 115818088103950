import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import _ from 'lodash'


const addDoc = doc =>
	({
		type: C.HEALTHCARE_PROFESSIONALS_NEW_DOC,
		payload: doc
	})

const updateDoc = doc =>
	({
		type: C.HEALTHCARE_PROFESSIONALS_UPDATE_DOC,
		payload: doc
	})

const deleteDoc = doc =>
	({
		type: C.HEALTHCARE_PROFESSIONALS_DELETE_DOC,
		payload: doc
	})


export const healthcareProfessionalsListener = (
	company_id = "all"
) => async (dispatch, getState) => {

	let query = company_id === 'all' ?
		db.collection(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.orderBy("registered") :
		db.collection(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.where("company_id", "==", company_id)
			.orderBy("registered")

	if(_.isFunction(window.healthcareProfessionalsListener)){
		window.healthcareProfessionalsListener()
		delete window.healthcareProfessionalsListener
	}

	window.healthcareProfessionalsListener = query
		.onSnapshot(querySnapshot => {
			querySnapshot.docChanges().forEach(change => {
				const doc = {id: change.doc.id, ...change.doc.data()}

				switch (change.type) {
					case 'added':
						dispatch(
							addDoc(doc)
						)
						break
					case 'modified':
						dispatch(
							updateDoc(doc)
						)
						break
					case 'removed':
						dispatch(
							deleteDoc(doc)
						)
						break
					default:
						break
				}

			})
		})

}

