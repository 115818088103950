import React, {Component} from 'react'
import '../../../assets/scss/Resources/ResourceForm.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import getKeywords from "../../../util/getKeywords"
import roleType from '../../../constants/roleType'
import isUrlValid from '../../../util/isUrlValid'
import isPhoneValid from '../../../util/isPhoneValid'
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'


class Form extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: props.id || null,
      company_id: !!props.companies ? props.companies.map(i => ({ label: i.display_name, value: i.company_id })).find(company => company.value === props.company_id) : { label: 'All', value: 'all' },
      title: props.title || "",
      index: props.index || 0,
      url: props.url || "",
      type: roleType.find(role => role.value === props.type),
      keywords: props.keywords || []
    }

  }

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value
    })

  handleReset = e => {
    e.preventDefault()
    this.setState({
      [e.target.dataset.field]: ""
    })
  }

  handleDelete = e => {
    e.preventDefault()
    this.props.handleDelete(this.state)
  }

  handleSubmit = e => {
    e.preventDefault()

    const { id, url, title, company_id, type, index } = this.state

    if (!company_id) {
      this.props.addError("Please select a company id")
    } else if (title.length === 0) {
      this.props.addError("Please enter a title")
    } else if (!(isUrlValid(url) || isPhoneValid(url))) {
      this.props.addError("The URL field is not a valid url or phone number")
    } else {
      const data = {
        id: id,
        type: type.value,
        index: `${index}`,
        url: url,
        title: title,
        company_id: company_id.value
      }

      data.keywords = Array.from(new Set([
        ...getKeywords(data.title.toLowerCase()),
        ...getKeywords(data.url.toLowerCase())
      ]))

      this.props.handleUpdate(data)
    }

  }


  render() {
    const { id, title, url, type, company_id, index } = this.state
    const { companies } = this.props

    const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

    return (
      <form
        key={id}
        className="edit-resource" onSubmit={this.handleSubmit}
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
      >

        <div className="company_id">

          <label data-tip={`The Company ID for the item`}>Company ID</label>

          <Select
            name="company_id"
            value={company_id}
            onChange={selected => this.setState({ company_id: selected })}
            options={[ { label: 'All', value: 'all' }, ...options]}
          />
        </div>

        <div className="indexInp">
          <label data-tip={`The rank order for the item`}>Order</label>
          <InputNumber
            name="index"
            min={0}
            value={index}
            defaultValue={0}
            onChange={ v => this.setState({index: v})}
          />
        </div>

        <div className="titleInp">
          <label data-tip={`The title for the item`}>Title</label>
          <input
            tabIndex="20"
            name="title"
            type="text"
            placeholder="title"
            value={title}
            onChange={this.handleChange}
          />
        </div>

        <div className="urlInp">
          <label data-tip={`The url for the item`}>Url</label>
          <input
            tabIndex="30"
            name="url"
            type="text"
            placeholder="url"
            value={url}
            onChange={this.handleChange}
          />
        </div>

        <div className="typeSel">
          <label data-tip={`The group for the item`}>Group</label>
          <Select
            name="type"
            value={type}
            onChange={selected => this.setState({ type: selected })}
            options={roleType}
          />
        </div>

        <input
          tabIndex="50"
          className="trashInp"
          onClick={this.handleDelete}
          type="image"
          alt="delete item"
          src={trashIcon}
          data-tip={`Click to delete record`}
        />

        <input
          tabIndex="60"
          className="updateInp btn"
          data-tip={`Click to update record`}
          type="submit"
          value="UPDATE"
        />

        <ReactTooltip />

      </form>
    )
  }
}

export default Form