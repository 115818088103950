import React from 'react'
import NavItem from './NavItem'
import Logout from './Logout'
import {NavLink} from 'react-router-dom'
import logo from '../../assets/svg/logo.svg'
import '../../assets/scss/Header.scss'


const Header = props =>
  <header id="masthead" className="site-header" role="banner">
    <div className="site-branding">
      <NavLink to="/admins"><img src={logo} alt="Care Connect Call Logo" /></NavLink>
    </div>
    <nav id="site-navigation" className="main-navigation" role="navigation">
      <ul className="menu nav-menu">
        <NavItem to="/admins" activeClassName="selected">Admins</NavItem>
        <NavItem to="/companies" activeClassName="selected">Companies</NavItem>
        <NavItem to="/company-admins" activeClassName="selected">Company Admins</NavItem>
        <NavItem to="/appointments" activeClassName="selected">Appointments</NavItem>
        <NavItem to="/healthcare-professionals" activeClassName="selected">Healthcare Professionals</NavItem>
        <NavItem to="/patients" activeClassName="selected">Patients</NavItem>
        <NavItem to="/calls" activeClassName="selected">Calls</NavItem>
        <NavItem to="/analytics" activeClassName="selected">Analytics</NavItem>
        <NavItem to="/resources" activeClassName="selected">Resources</NavItem>
        <NavItem to="/help" activeClassName="selected">Help</NavItem>
      </ul>
    </nav>
    <Logout {...props} />
  </header>


export default Header
