import React, {useEffect} from 'react'
import Collapse, {Panel} from '../Collapse'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import Form from './Form'
import Pagination from '../Pagination/Pagination'
import _ from 'lodash'
import '../../../assets/scss/Patients/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import QuickSearchWithFilter from "../QuickSearchWithFilter"
import LegalInfoFooter from "../LegalInfoFooter"


const Patients = ({
  query,
  addQuery,
  companyFilter,
  addCompanyFilter,
  docs,
  companies,
  fetching,
  getPatients,
  setDoc,
  deleteDoc,
  nextAvailable,
  prevAvailable,
	addError
}) => {

	const queryData = _.debounce((loadNext, loadPrev) => getPatients(loadNext, loadPrev), 500)

	useEffect(() => {
		queryData()
	}, [query, companyFilter]);

	const handleUpdate = data => setDoc(data)

	const handleDelete = data => deleteDoc(data)

	return (
			<main className="app patients">

				{fetching &&
					<ProgressBarIndeterminate />
				}
				<ShowErrors/>
				<Header/>
				<article className="inner-content">

				<EntryHeader title="Patients"/>
					<QuickSearchWithFilter
						query = {query}
						companyFilter = {companyFilter}
						addQuery = {addQuery}
						addCompanyFilter = {addCompanyFilter}
						companies = {companies}
						linkTo = "patients/add"
						linkTitle = "ADD PATIENT"
					/>

					<section className="content">

						<Collapse accordion={true}>

							{docs.map(doc =>
								<Panel header={doc.patient_id} key={doc.id} headerClass="edit-patient-panel">
									<Form
										key={doc.id}
										{...doc}
										companies={companies}
										handleUpdate={ data => handleUpdate(data) }
										handleDelete={ data => handleDelete(data) }
										addError={addError}
									/>
								</Panel>
							)}

						</Collapse>


						{(!fetching && docs.length === 0) &&
							<p>No data</p>
						}

						{(prevAvailable || nextAvailable) &&
							<Pagination
							isPreviousAvailable = {prevAvailable}
							isNextAvailable = {nextAvailable}
							handlePreviousClick = {() => queryData(false, true)}
							handleNextClick = {() => queryData(true, false)}
							/>
						}

				</section>
			</article>
				<LegalInfoFooter />

			</main>
		)
}


export default Patients