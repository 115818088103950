import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db, FieldValue, Timestamp} from '../index'
import {addError} from "../error"
import _ from 'lodash'
import moment from "moment"
import getKeywords from "../../util/getKeywords"


const isFetching = fetching =>
	({
		type: C.APPOINTMENT_FETCHING_DOCS,
		payload: fetching
	})

export const addQuery = query =>
	({
		type: C.APPOINTMENT_QUERY_TERM,
		payload: query
	})

export const addDate = date =>
	({
		type: C.APPOINTMENT_QUERY_DATE_RANGE,
		payload: date
	})

export const addCompanyID = company_id =>
	({
		type: C.APPOINTMENT_QUERY_COMPANY_ID,
		payload: company_id
	})

const removeDoc = doc =>
	({
		type: C.APPOINTMENT_DELETE_DOC,
		payload: doc
	})

const updateDoc = doc =>
	({
		type: C.APPOINTMENT_UPDATE_DOC,
		payload: doc
	})

const addDoc = doc =>
	({
		type: C.APPOINTMENT_NEW_DOC,
		payload: doc
	})

export const cancelAppointment = doc => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {

		await db.collection(COLLECTION.APPOINTMENTS)
			.doc(doc.id)
			.delete()

		const increment = FieldValue.increment(-1)
		await db.collection(COLLECTION.COUNTERS)
			.doc(COLLECTION.APPOINTMENTS)
			.update({
				total: increment
			})

	} catch (error) {
		dispatch(
			addError(error.message)
		)

		dispatch(
			isFetching(false)
		)
	}
}


export const appointmentListener  = () => async (dispatch, getState) => {

	const {appointments: {docs = [], query = "", date = moment().toDate(), company_id = "all"}} = getState()

	dispatch(
		isFetching(true)
	)

	let querySnapshot = await db.collection(COLLECTION.APPOINTMENTS)
		.where('date', '>=', moment(date).startOf('month').toDate())
		.where('date', '<=', moment(date).endOf('month').toDate())

	if(company_id !== "all"){
		querySnapshot = querySnapshot
			.where('company_id', '==', company_id)
	}

	if(query.length > 0){
		querySnapshot = querySnapshot
			.where("keywords", "array-contains", query.toLowerCase())
	}

	if(_.isFunction(window.appointmentListener)){
		window.appointmentListener()
		delete window.appointmentListener
	}

	window.appointmentListener = querySnapshot
		.onSnapshot(querySnapshot => {

			dispatch(
				isFetching(false)
			)

			const queryDocs = querySnapshot.docs.map(i => ({id: i.id, ...i.data()}))

			docs.filter(i => !queryDocs.find( j => i.id === j.id  ))
				.forEach( i => dispatch( removeDoc(i) ) )

			querySnapshot.docChanges().forEach(change => {

				const doc = {id: change.doc.id, ...change.doc.data() }

				switch (change.type) {
					case 'added':
						dispatch(
							addDoc(doc)
						)
						break
					case 'modified':
						dispatch(
							updateDoc(doc)
						)
						break
					case 'removed':
						dispatch(
							removeDoc(doc)
						)
						break
					default:
						break
				}

			})
		}, error => {
			dispatch(
				isFetching(false)
			)
			dispatch(
				addError(error.message)
			)
		})
}


export const setAppointment = (hcpDoc, patientDoc, date) => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {

		const company_id = hcpDoc.company_id

		const patient = {
			patient_id: patientDoc.patient_id,
			uid: patientDoc.id,
			keywords: Array.from(new Set([
				...getKeywords(patientDoc.patient_id)
			]))
		}

		if(!!patientDoc.fcm_token){
			patient.fcm_token = patientDoc.fcm_token
		}

		if(!!patientDoc.pushkit_token){
			patient.pushkit_token = patientDoc.pushkit_token
		}

		const hcp = {
			hcp_id: hcpDoc.hcp_id,
			uid: hcpDoc.id,
			keywords: Array.from(new Set([
				...getKeywords(hcpDoc.hcp_id)
			]))
		}

		if(!!hcpDoc.fcm_token){
			hcp.fcm_token = hcpDoc.fcm_token
		}

		if(!!hcpDoc.pushkit_token){
			hcp.pushkit_token = hcpDoc.pushkit_token
		}

		const temp = {
			company_id: company_id,
			date: Timestamp.fromDate(date.toDate()),
			patient: patient,
			health_care_professional: hcp,
			keywords: Array.from(new Set([
				...patient.keywords,
				...hcp.keywords
			]))
		}

		await db.collection(COLLECTION.APPOINTMENTS)
			.add(temp)

	} catch (error) {
		dispatch(
			addError(error.message)
		)
	} finally {
		dispatch(
			isFetching(false)
		)
	}

}

export const rescheduleAppointment = (
	appointment,
	date
) => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {
		await db.collection(COLLECTION.APPOINTMENTS)
			.doc(appointment.id)
			.update({
				date: Timestamp.fromDate(date)
			})
	} catch (error) {
		dispatch(
			addError(error.message)
		)
	} finally {
		dispatch(
			isFetching(false)
		)
	}

}