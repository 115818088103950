import PrivateRoute from '../ui/PrivateRoute'
import {connect} from 'react-redux'
import {dataListeners} from "../../actions/listeners"


const mapStateToProps = (state, props) =>
  ({
    user: state.auth.user
  })

const mapDispatchToProps = dispatch =>
  ({
    dataListeners() {
      dispatch(
        dataListeners()
      )
    }
  })

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)