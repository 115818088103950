import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/Resources/AddResource.scss'
import getKeywords from "../../../util/getKeywords";
import ReactTooltip from 'react-tooltip'
import roleType from '../../../constants/roleType'
import Select from 'react-select'
import isUrlValid from '../../../util/isUrlValid'
import isPhoneValid from '../../../util/isPhoneValid'
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'
import ButtonCloseWrapper from "../ButtonWithCloseIcon"
import LegalInfoFooter from "../LegalInfoFooter"


class AddResource extends Component {

  state = {
    company_id: { label: 'All', value: 'all' },
    title: "",
    url: "",
    type: {
      label: "All",
      value: "all"
    },
    index: 1
  }

  handleChange = e => { this.setState({ [e.target.name]: e.target.value }) }

  handleReset = e => {
    e.preventDefault()
    this.setState({
      company_id: { label: 'All', value: 'all' },
      title: "",
      url: "",
      type: {
        label: "All",
        value: "all"
      },
      index: 1
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { url, title, company_id, type, index } = this.state

    if (!company_id) {
      this.props.addError("Please select a company id")
    } else if (title.length === 0) {
      this.props.addError("Please enter a title")
    } else if (!(isUrlValid(url) || isPhoneValid(url))) {
      this.props.addError("The URL field is not a valid url or phone number")
    } else {
      const data = {
        type: type.value,
        index: `${index}`,
        url: url,
        title: title,
        company_id: company_id.value
      }

      data.keywords = Array.from(new Set([
        ...getKeywords(data.title.toLowerCase()),
        ...getKeywords(data.url.toLowerCase())
      ]))

      this.props.addDoc(data)
      this.props.history.push('/resources')
    }
  }

  handleFieldReset = e => {
    e.preventDefault()
    const field = e.currentTarget.dataset.field ?? ""
    if(!!field){
      this.setState({
        [field]: ""
      })
    }
  }

  render() {
    const { title, url, type, company_id, index } = this.state
    const { companies } = this.props

    const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

    return (
      <main className="app add-resource">
        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Add Resource" />

          <section className="content">

            <h3>New Resource</h3>

            <Link to="/resources" className="close" data-tip={`Click to close`}><img src={close} alt="close" /></Link>

            <form
              className="add-resource-form"
              onSubmit={this.handleSubmit}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
            >

              <div className="company_id">
                <label data-tip={`The title for the item`}>Company ID</label>
                <Select
                  name="company_id"
                  value={company_id}
                  onChange={selected => this.setState({ company_id: selected })}
                  options={[ { label: 'All', value: 'all' }, ...options]}
                />
              </div>


              <div className="index">
                <label data-tip={`The rank order for the item`}>Order</label>
                <InputNumber
                  name="index"
                  min={0}
                  defaultValue={0}
                  value={index}
                  onChange={ v => this.setState({index: v})}
                />
              </div>


              <div className="title">
                <label data-tip={`The title for the item`}>Title</label>
                <input
                  name="title"
                  type="text"
                  placeholder="title"
                  value={title}
                  onChange={this.handleChange}
                />
                {title.length > 0 &&
                  <ButtonCloseWrapper data-field="title" onClick={this.handleFieldReset} type="reset" />
                }
              </div>

              <div className="url">
                <label data-tip={`The url for the item`}>Url</label>
                <input
                  name="url"
                  type="text"
                  placeholder="url"
                  value={url}
                  onChange={this.handleChange}
                />
                {url.length > 0 &&
                  <ButtonCloseWrapper data-field="url" onClick={this.handleFieldReset} type="reset" />
                }
              </div>

              <div className="type">
                <label data-tip={`The group for the item`}>Group</label>
                <Select
                  name="type"
                  value={type}
                  onChange={selected => {
                    this.setState({ type: selected })
                  }}
                  options={roleType}
                />
              </div>

              <input
                className="reset"
                onClick={this.handleReset}
                type="image"
                src={trashIcon}
                alt="delete icon"
                data-tip={`Click to reset record`}
              />

              <input
                data-tip={`Click to add record`}
                className="add btn"
                type="submit"
                value="ADD RESOURCE"
              />

              <ReactTooltip />

            </form>

          </section>
        </article>
        <LegalInfoFooter />
      </main>
    )
  }
}


export default AddResource