import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db, FieldValue, Timestamp} from '../index'
import {addError} from "../error"
import Role from "../../constants/Role"


const isFetching = fetching =>
	({
		type: C.HEALTHCARE_PROFESSIONALS_FETCHING_DOCS,
		payload: fetching
	})

export const addDoc = doc => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {
		const hcp_id = doc.hcp_id

		const querySnapshot = await db.collection(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.where("hcp_id", "==", hcp_id)
			.get()

		if (!querySnapshot.empty) {
			throw new Error(`Healthcare Professional with id: ${hcp_id} already exists. Please use a unique id.`)
		}

		const temp = {
			hcp_id: doc.hcp_id,
			company_id: doc.company_id,
			password: doc.password,
			registered: Timestamp.now(),
			online: {
				status: false,
				last_updated: Timestamp.now(),
			},
			is_auth: doc.is_auth,
			keywords: doc.keywords
		}

		const id = db.collection(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.doc()
			.id + Role.HEALTH_CARE_PROFESSIONAL

		await db.collection(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.doc(id)
			.set(temp)

		const increment = FieldValue.increment(1)
		await db.collection(COLLECTION.COUNTERS)
			.doc(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.update({
				total: increment
			})

	} catch (error) {

		dispatch(
			addError(error.message)
		)

	} finally {
		dispatch(
			isFetching(false)
		)
	}

}
