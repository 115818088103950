import React from 'react'
import styled from "styled-components"
import ChevronLeft from './ChevronLeft'
import ChevronRight from './ChevronRight'

const LiWrapper = styled.li`
	cursor: pointer;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const PaginationWrapper = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  align-items: center;
  margin: 50px 0 0 0;
  padding: 0;
  justify-content: flex-end;
`

const Pagination = ({
  isPreviousAvailable = false,
  isNextAvailable = false,
  handlePreviousClick,
  handleNextClick
}) => {

	return (
		<PaginationWrapper>
			<LiWrapper
			    onClick={e => {
				    e.preventDefault()
				    if(isPreviousAvailable){
					    handlePreviousClick()
				    }
			    }}>
			<ChevronLeft enabled={isPreviousAvailable} />
		</LiWrapper>
		<LiWrapper
		    onClick={e => {
		      e.preventDefault()
			    if(isNextAvailable){
				    handleNextClick()
			    }
		    }}>
			<ChevronRight enabled={isNextAvailable} />
	</LiWrapper>
</PaginationWrapper>
)

}


export default Pagination