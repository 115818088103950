import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import '../../assets/scss/QuickSearchWithCompanyFilter.scss'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import ButtonWithCloseIcon from "./ButtonWithCloseIcon"


class QuickSearchWithCompanyFilter extends Component {

  state = {
    quickSearch: "",
    companyFilter: {label: 'All Companies', value: 'all'}
  }

  handleReset = e => {
    e.preventDefault()
    const {companyFilter} = this.state
    this.setState({
      quickSearch: ""
    })
    this.props.handleQuickSearch("", companyFilter)
  }

  handleQuickSearchChange = () => {
    const { quickSearch, companyFilter } = this.state

    this.props.handleQuickSearch(quickSearch.toLowerCase(), companyFilter)
  }

  handleQuickSearch = e => {
    e.preventDefault()
  }

  render() {
    const { companyFilter, quickSearch } = this.state
    const { linkTo = "", linkTitle = "", companies = [] } = this.props
    const options = companies.map(i => ({ label: i.display_name, value: i.company_id }))

    return (
      <section className="quick-search">
        <form onSubmit={this.handleQuickSearch}>
          <div className="quick-search-container">
            <input
              type="text"
              placeholder="Quick search"
              value={quickSearch}
              onChange={e => this.setState({ quickSearch: e.target.value }, this.handleQuickSearchChange)}
            />
            {this.state.quickSearch.length > 0 &&
              <ButtonWithCloseIcon onClick={this.handleReset} type="reset" />
            }
          </div>

          <Select
            id="filter-by-company-id"
            name="filter"
            placeholder="Please select company..."
            value={companyFilter}
            onChange={selected => this.setState({ companyFilter: selected }, this.handleQuickSearchChange)}
            options={[ { label: 'All Companies', value: 'all' }, ...options]}
          />

          {this.props.entries > 0 > 0 &&
            <p>{this.props.entries} entries</p>
          }
        </form>

        <Link to={linkTo} className="btn" data-tip="Click to add record">{linkTitle}</Link>
        <ReactTooltip />

      </section>
    )
  }
}

export default QuickSearchWithCompanyFilter