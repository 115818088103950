import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import Role from '../../constants/Role'
import {db, FieldValue, Timestamp} from '../index'
import {addError} from "../error"


const isFetching = fetching =>
  ({
    type: ACTION.COMPANY_ADMIN_FETCHING_DOCS,
    payload: fetching
  })

export const addDoc = doc => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    const admin_id = doc.admin_id

    const querySnapshotAdminID = await db
      .collection(COLLECTION.COMPANY_ADMINS)
      .where("admin_id", "==", admin_id)
      .get()

    if (!querySnapshotAdminID.empty) {
      throw new Error(`Company Admin with id: ${admin_id} already exists. Please use a unique cms admin id.`)
    }

    const querySnapshotCompanyAdminTelephone = await db
      .collection(COLLECTION.COMPANY_ADMINS)
      .where("telephone", "==", doc.telephone)
      .get()

    if (!querySnapshotCompanyAdminTelephone.empty) {
      throw new Error(`Company Admin with telephone: ${doc.telephone} already exists. Please use a unique telephone.`)
    }

    const querySnapshotSuperAdminTelephone = await db
      .collection(COLLECTION.SUPER_ADMINS)
      .where("telephone", "==", doc.telephone)
      .get()

    if (!querySnapshotSuperAdminTelephone.empty) {
      throw new Error(`Admin with telephone: ${doc.telephone} already exists. Please use a unique telephone.`)
    }

    const temp = {
      admin_id: doc.admin_id,
      company_id: doc.company_id,
      telephone: doc.telephone,
      registered: Timestamp.now(),
      is_auth: doc.is_auth,
      keywords: doc.keywords
    }

    const id = db.collection(COLLECTION.COMPANY_ADMINS)
      .doc()
      .id + Role.COMPANY_ADMIN

    await db.collection(COLLECTION.COMPANY_ADMINS)
      .doc(id)
      .set(temp)

    const increment = FieldValue.increment(1)
    await db.collection(COLLECTION.COUNTERS)
      .doc(COLLECTION.COMPANY_ADMINS)
      .update({
        total: increment
      })

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}