import React, {Component} from 'react'
import {Timestamp} from '../../../actions'
import '../../../assets/scss/Patients/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import ReactTooltip from 'react-tooltip'
import Select from "react-select"
import getKeywords from "../../../util/getKeywords"


class Form extends Component {

	constructor(props) {
		super(props)

		this.state = {
			id: props.id || null,
			company_id: !!props.companies ? props.companies.map(i => ({ label: i.display_name, value: i.company_id })).find(company => company.value === props.company_id) : null,
			password: props.password || "",
			patient_id: props.patient_id || "",
			registered: props.registered || Timestamp.now(),
			online: {
				status: props.status || false,
				last_updated: props.last_updated || Timestamp.now()
			},
			is_auth: props.is_auth || false,
			keywords: props.keywords || []
		}

		if (props.fcm_token) {
			this.state.fcm_token = props.fcm_token
		}

		if (props.pushkit_token) {
			this.state.pushkit_token = props.pushkit_token
		}

	}

	handleChange = e => this.setState({[e.target.name]: e.target.value})

	handleDelete = e => {
		e.preventDefault()
		this.props.handleDelete(this.state)
	}

	handleSubmit = e => {
		e.preventDefault()

		const {id, patient_id, company_id, password, is_auth, registered, online, fcm_token, pushkit_token} = this.state

		if (!company_id) {
			this.props.addError("Please select a company id")
		} else if (patient_id.length === 0) {
			this.props.addError("Please enter a patient id")
		} else if (password.length === 0) {
			this.props.addError("Please enter a password")
		} else {
			const data = {
				id: id,
				patient_id: patient_id.toLowerCase().replace(/\s/g, ''),
				password: password.toLowerCase().replace(/\s/g, ''),
				is_auth: is_auth,
				registered: registered,
				company_id: company_id.value,
				online: online
			}

			if(!!fcm_token){
				data.fcm_token = fcm_token
			}

			if(!!pushkit_token){
				data.pushkit_token = pushkit_token
			}

			data.keywords = Array.from(new Set([
				...getKeywords(data.patient_id.toLowerCase())
			]))

			this.props.handleUpdate(data)
		}
	}

	render() {
		const { patient_id, company_id, password } = this.state

		const { companies } = this.props

		const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

		return (
			<form
				className="edit-patient-form"
				onSubmit={this.handleSubmit}
				onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
			>

				<div className="company_id">
					<label data-tip={`The Company ID for the item`}>Company ID</label>
					<Select
						name="company_id"
						value={company_id}
						placeholder="Please select company..."
						onChange={selected => this.setState({ company_id: selected })}
						options={options}
					/>
				</div>

				<div className="patient_id">
					<label data-tip={`Patient ID`}>Patient ID</label>
					<input
						tabIndex="10"
						name="patient_id"
						type="text"
						placeholder="patient ID"
						value={patient_id}
						onChange={this.handleChange}
						readOnly
					/>
				</div>

				<div className="password">
					<label data-tip={`Password for Patient ID`}>Password</label>
					<input
						tabIndex="20"
						name="password"
						type="text"
						placeholder="password"
						value={password}
						onChange={this.handleChange}
					/>
				</div>

				<input
					className="trash"
					alt="trash icon"
					onClick={this.handleDelete}
					type="image"
					src={trashIcon}
					data-tip={`Click to delete record`}
				/>

				<input
					className="update btn"
					type="submit"
					value="UPDATE"
					data-tip={`Click to update record`}
				/>

				<ReactTooltip/>

			</form>
		)
	}
}

export default Form