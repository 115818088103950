import React from 'react'
import '../../assets/scss/Logout.scss'


const Logout = props =>
  <svg className="logout" viewBox="0 0 44 44" onClick={props.signOut}>
    <path
      fill="#A7A8AA"
      d="M6 3.98c.23-.55.37-1.15.68-1.67C7.35 1.18 8.25.42 9.42.08c.18-.05.37-.05.56-.05 3.7-.01 7.4-.01 11.1-.01 3.48 0 6.97.01 10.45-.02.9-.01 1.63.35 2.32.91.79.66 1.35 1.52 1.67 2.59.17.58.16 1.19.17 1.79.02 2.68 0 5.36 0 8.04 0 .1.02.22-.08.27-.1.05-.16-.06-.23-.12-.74-.74-1.48-1.49-2.24-2.22-.22-.21-.31-.43-.31-.76.01-1.1.01-2.19-.01-3.29-.02-.84-.03-1.68-.11-2.52-.05-.61-.65-1.26-1.19-1.31-.44-.05-.88-.06-1.32-.07-3.68-.07-7.37-.09-11.05-.04-2.38.03-4.76.01-7.13.05-.62.01-1.24.03-1.86.07-.65.03-1.22.85-1.23 1.76-.01 1.36-.01 2.73-.01 4.09-.01 6.7-.01 13.4-.01 20.11 0 3.07.03 6.15.01 9.23-.01 1.19.45 2.17 1.88 2.15 1.39-.02 2.78-.01 4.17.01 4.42.07 8.84.02 13.26.03 1 0 2.01-.03 3.01-.04.67-.01 1.46-.69 1.5-1.57.1-1.84.08-3.67.09-5.51 0-.42.12-.7.4-.96.73-.68 1.42-1.4 2.12-2.1.08-.07.15-.21.27-.14.11.06.07.2.07.31.01 2.55.01 5.11.01 7.66 0 .5-.01.99-.07 1.48-.15 1.24-.71 2.21-1.53 3.01-.46.44-.98.75-1.54.98-.22.09-.45.07-.67.07-7.27 0-14.53 0-21.8.01-.14 0-.29-.03-.43.03H9.5c-1.01-.28-1.87-.88-2.53-1.81-.48-.68-.84-1.43-.89-2.35-.02-.4-.01-.8-.08-1.2V3.98zm0 0"
    />
    <path
      fill="#A7A8AA"
      d="M37.85 22.31c-1.74 1.77-3.48 3.53-5.23 5.29-1.85 1.86-3.72 3.7-5.57 5.57-.32.33-.64.28-.98.15-.35-.13-.57-.43-.63-.84-.13-.82-.09-1.64-.11-2.46-.01-.75-.03-1.51-.03-2.26 0-.3-.1-.39-.36-.39h-8.56c-.79 0-1.45-.76-1.45-1.67v-6.9c0-.88.69-1.67 1.46-1.67h8.51c.38 0 .38-.01.39-.45.06-1.24.02-2.47.06-3.7.02-.49-.01-1 .24-1.45.3-.52.91-.66 1.33-.26 1.09 1.06 2.18 2.15 3.27 3.23 1.59 1.58 3.18 3.17 4.77 4.76.96.97 1.93 1.95 2.89 2.93v.12zm0 0"
    />
  </svg>

export default Logout
