import React from 'react'
import ReactTable from "react-table"
import "react-table/react-table.css"
import matchSorter from 'match-sorter'
import _ from 'lodash'


const FeedbackTable = ({data = []}) => {

	const numberOfRows = data.length > 25 ? 25 : data.length


	return (
		<ReactTable
			data={data}
			filterable
			defaultFilterMethod={(filter, row) =>
				String(row[filter.date]) === filter.value}
			columns={[
				{
					columns: [
						{
							Header: "Date",
							id: "date",
							accessor: d => d.date_formatted,
							filterMethod: (filter, rows) =>
								matchSorter(rows, filter.value, {keys: ["date"]}),
							filterAll: true
						}, {
							Header: "User",
							id: "role",
							accessor: d => _.capitalize(d.role),
							filterMethod: (filter, rows) =>
								matchSorter(rows, filter.value, {keys: ["role"]}),
							filterAll: true
						}, {
							Header: "Rating",
							id: "rating",
							accessor: d => d.rating,
							filterMethod: (filter, rows) =>
								matchSorter(rows, filter.value, {keys: ["rating"]}),
							filterAll: true
						}, {
							Header: "Comment",
							id: "comment",
							accessor: d => d.comment,
							filterMethod: (filter, rows) =>
								matchSorter(rows, filter.value, {keys: ["comment"]}),
							filterAll: true,
							style: {'whiteSpace': 'unset'}
						}
					]
				}
			]}
			defaultSorted={[
				{
					id: "date",
					desc: false
				}
			]}
			def
			defaultPageSize={numberOfRows}
			className="-striped -highlight"
		/>
	)

}


export default FeedbackTable
