import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db, FieldValue, Timestamp} from '../index'
import {addError} from "../error"


const isFetching = fetching =>
	({
		type: C.HEALTHCARE_PROFESSIONALS_FETCHING_DOCS,
		payload: fetching
	})

const remove = doc =>
	({
		type: C.HEALTHCARE_PROFESSIONALS_DELETE_DOC,
		payload: doc
	})

const update = doc =>
	({
		type: C.HEALTHCARE_PROFESSIONALS_UPDATE_DOC,
		payload: doc
	})

export const deleteDoc = doc => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {
		await db.collection(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.doc(doc.id)
			.delete()

		const increment = FieldValue.increment(-1)
		await db.collection(COLLECTION.COUNTERS)
			.doc(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.update({
				total: increment
			})

		dispatch(
			remove(doc)
		)

	} catch (error) {
		dispatch(
			addError(error.message)
		)
	} finally {
		dispatch(
			isFetching(false)
		)
	}

}

export const setDoc = doc => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {
		const docID = doc.id
		const data = {
			hcp_id: doc.hcp_id,
			company_id: doc.company_id,
			password: doc.password,
			registered: new Timestamp(doc.registered.seconds, doc.registered.nanoseconds),
			online: doc.online,
			is_auth: doc.is_auth,
			keywords: doc.keywords
		}

		if (doc.fcm_token) {
			data.fcm_token = doc.fcm_token
		}

		if (doc.pushkit_token) {
			data.pushkit_token = doc.pushkit_token
		}

		await db.collection(COLLECTION.HEALTH_CARE_PROFESSIONALS)
			.doc(docID)
			.set(data)

		dispatch(
			update(doc)
		)

	} catch (error) {

		dispatch(
			addError(error.message)
		)
	} finally {
		dispatch(
			isFetching(false)
		)
	}

}