import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db, FieldValue, Timestamp} from '../index'
import {addError} from "../error"


const isFetching = fetching =>
  ({
    type: ACTION.COMPANY_ADMIN_FETCHING_DOCS,
    payload: fetching
  })

const remove = doc =>
  ({
    type: ACTION.COMPANY_ADMIN_DELETE_DOC,
    payload: doc
  })

const update = doc =>
  ({
    type: ACTION.COMPANY_ADMIN_UPDATE_DOC,
    payload: doc
  })

export const deleteDoc = doc => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    await db.collection(COLLECTION.COMPANY_ADMINS)
      .doc(doc.id)
      .delete()

    const increment = FieldValue.increment(-1)
    await db.collection(COLLECTION.COUNTERS)
      .doc(COLLECTION.COMPANY_ADMINS)
      .update({
        total: increment
      })

    dispatch(
      remove(doc)
    )

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}

export const setDoc = doc => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    const queryCompanyAdminSnapshot = await db
      .collection(COLLECTION.COMPANY_ADMINS)
      .where("telephone", "==", doc.telephone)
      .get()

    const adminDocs = queryCompanyAdminSnapshot.docs
      .map( i => ({id: i.id, ...i.data()}))
      .find(i => i.id !== doc.id)

    if (!!adminDocs) {
      throw new Error(`Company Admin with telephone: ${doc.telephone} already exists. ` +
        `Please use a unique telephone.`)
    }

    const querySuperAdminSnapshot = await db
      .collection(COLLECTION.SUPER_ADMINS)
      .where("telephone", "==", doc.telephone)
      .get()

    const superAdminDocs = querySuperAdminSnapshot.docs
      .map( i => ({id: i.id, ...i.data()}))
      .find(i => i.id !== doc.id)

    if (!!superAdminDocs) {
      throw new Error(`Admin with telephone: ${doc.telephone} already exists. ` +
        `Please use a unique telephone.`)
    }

    const docID = doc.id
    const temp = {
      admin_id: doc.admin_id,
      company_id: doc.company_id,
      telephone: doc.telephone,
      keywords: doc.keywords,
      is_auth: doc.is_auth,
      registered: new Timestamp(doc.registered.seconds, doc.registered.nanoseconds),
    }


    await db.collection(COLLECTION.COMPANY_ADMINS)
      .doc(docID)
      .set(temp)

    dispatch(
      update(doc)
    )

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}