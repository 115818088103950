import React from 'react'
import routes from './routes'
import {persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/lib/integration/react'
import {configureStore} from './store'
import {Provider} from 'react-redux'
import {render} from 'react-dom'
import * as serviceWorker from './serviceWorker'
import {auth} from "./actions"

const store = configureStore()
const persistor = persistStore(store)

if(!auth.currentUser){
  persistor.purge()
}

render(
  <Provider store={store}>
    <PersistGate
      loading={<div>Loading...</div>}
      persistor={persistor}>
      {routes}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
