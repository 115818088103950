import AddPatient from '../../ui/Patients/AddPatient'
import {connect} from 'react-redux'
import {addDoc} from '../../../actions/patients/addDoc'
import {addError} from '../../../actions/error'


const mapStateToProps = (state, props) =>
	({
		companies: state.companies.docs,
		fetching: state.patients.fetching
	})

const mapDispatchToProps = dispatch =>
	({
		addDoc(doc) {
			dispatch(
				addDoc(doc)
			)
		},
		addError(error) {
			dispatch(
				addError(error)
			)
		}
	})

export default connect(mapStateToProps, mapDispatchToProps)(AddPatient)  
