import React, {Component} from 'react'
import Collapse, {Panel} from '../Collapse'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import Form from './Form'
import '../../../assets/scss/HealthcareProfessionals/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import QuickSearchWithCompanyFilter from "../QuickSearchWithCompanyFilter";
import LegalInfoFooter from "../LegalInfoFooter"


class HealthcareProfessionals extends Component {

	state = {
		query: "",
		companyFilter: 'all'
	}

	handleQuickSearch = (query, companyFilter) =>
		this.setState({
			query: query,
			companyFilter: companyFilter.value || null
		})

	handleUpdate = data =>  this.props.setDoc(data)

	handleDelete = data => this.props.deleteDoc(data)

	render() {
		const { docs, companies, fetching = false } = this.props
		const { query, companyFilter } = this.state

		let filtered = docs

		if (companyFilter !== 'all') {
			filtered = filtered.filter(doc => doc.company_id === companyFilter)
		}

		if (query.length > 0) {
			filtered = filtered.filter(doc => JSON.stringify(doc).toLowerCase().includes(query))
		}

		return (
			<main className="app healthcare-professionals">
				{fetching &&
					<ProgressBarIndeterminate />
				}
				<ShowErrors/>
				<Header/>
				<article className="inner-content">
					<EntryHeader title="Healthcare Professionals"/>
					<QuickSearchWithCompanyFilter
						handleQuickSearch={this.handleQuickSearch}
						companies={companies}
						entries={filtered.length}
						linkTo="healthcare-professionals/add"
						linkTitle="ADD HEALTHCARE PROFESSIONAL"
					/>

					<section className="content">

						<Collapse accordion={true}>

							{filtered.map(doc =>
								<Panel header={doc.hcp_id} headerClass="edit-healthcare-professional-panel" key={doc.id}>
									<Form
										companies={companies}
										key={doc.id}
										{...doc}
										handleDelete={this.handleDelete}
										handleUpdate={this.handleUpdate}
									/>
								</Panel>
							)}

						</Collapse>

						{filtered.length === 0 &&
							<p>No data</p>
						}

					</section>
				</article>
				<LegalInfoFooter />
			</main>
		)
	}
}

export default HealthcareProfessionals