import AddHealthcareProfessional from '../../ui/HealthcareProfessionals/AddHealthcareProfessional'
import {connect} from 'react-redux'
import {addDoc} from '../../../actions/healthcare_professionals/addDoc'
import {addError} from '../../../actions/error'


const mapStateToProps = (state, props) =>
	({
		companies: state.companies.docs,
		fetching: state.healthcare_professionals.fetching
	})

const mapDispatchToProps = dispatch =>
	({
		addDoc(doc) {
			dispatch(
				addDoc(doc)
			)
		},
		addError(error) {
			dispatch(
				addError(error)
			)
		}
	})

export default connect(mapStateToProps, mapDispatchToProps)(AddHealthcareProfessional)
