import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/SuperAdmins/Add.scss'
import getKeywords from '../../../util/getKeywords'
import ReactTooltip from 'react-tooltip'
import {Timestamp} from '../../../actions'
import ButtonCloseWrapper from "../ButtonWithCloseIcon"
import LegalInfoFooter from "../LegalInfoFooter"


class AddSuperAdmin extends Component {

  state = {
    admin_id: "",
    telephone: "",
    is_auth: false,
    keywords: [],
    registered: Timestamp.now()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleDelete = e => {
    e.preventDefault()
    this.setState({
      admin_id: "",
      telephone: "",
      is_auth: false,
      keywords: [],
      registered: Timestamp.now()
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { admin_id, telephone, is_auth, registered } = this.state

    if (admin_id.length === 0) {
      this.props.addError("Please enter an Admin ID")
    } else if (telephone.length === 0) {
      this.props.addError("Please enter a telephone")
    } else {
      const data = {
        admin_id: admin_id.toLowerCase().replace(/\s/g, ''),
        telephone: telephone.replace(/\s/g, ''),
        is_auth: is_auth,
        registered: registered
      }

      data.keywords = Array.from(new Set([
        ...getKeywords(data.admin_id.toLowerCase())
      ]))

      this.props.addDoc(data)
      this.props.history.push('/admins')
    }

  }

  handleAdminIDReset = e => {
    e.preventDefault()
    this.setState({
      admin_id: ""
    })
  }

  handleTelephoneReset = e => {
    e.preventDefault()
    this.setState({
      telephone: ""
    })
  }


  render() {

    return (
      <main className="app add-admin">
        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Add Admin" />

          <section className="content">

            <h3>New Admin</h3>
            <Link to="/admins" className="close" data-tip={`Click to close`}>
              <img src={close} alt="" />
            </Link>

            <form
              className="add-admin-form"
              onSubmit={this.handleSubmit}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
            >

              <div className="admin_id">
                <label data-tip={`CMS Admin ID`}>Admin ID</label>
                <input
                  name="admin_id"
                  type="text"
                  placeholder="Please enter an Admin ID..."
                  value={this.state.admin_id}
                  onChange={this.handleChange}
                />
                {this.state.admin_id.length > 0 &&
                  <ButtonCloseWrapper
                    className="close-icon"
                    onClick={this.handleAdminIDReset}
                    type="reset"
                  />
                }
              </div>

              <div className="telephone">
                <label data-tip={`Telephone for Admin`}>Telephone</label>
                <input
                  name="telephone"
                  type="text"
                  placeholder="Please enter the telephone..."
                  value={this.state.telephone}
                  onChange={this.handleChange}
                />
                {this.state.telephone.length > 0 &&
                  <ButtonCloseWrapper
                    className="close-icon"
                    onClick={this.handleTelephoneReset}
                    type="reset"
                  />
                }
              </div>

              <input
                className="reset"
                onClick={this.handleDelete}
                type="image"
                alt=""
                src={trashIcon}
                data-tip={`Click to reset record`}
              />

              <input
                data-tip={`Click to add record`}
                className="add btn"
                type="submit"
                value="ADD ADMIN"
              />

              <ReactTooltip />

            </form>

          </section>

        </article>

        <LegalInfoFooter />

      </main>
    )
  }
}

export default AddSuperAdmin