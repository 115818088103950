const COLLECTION = {
  SUPER_ADMINS: 'super_admins',
  COMPANY_ADMINS: 'company_admins',
  TWILIO_ROOMS: 'twilio_rooms',
  COMPANIES: 'companies',
  HEALTH_CARE_PROFESSIONALS: 'health_care_professionals',
  PATIENTS: 'patients',
  APPOINTMENTS: 'appointments',
  COUNTERS: 'counters',
  FEEDBACK: 'feedback',
  CALLS: 'calls',
  CHAT_CHANNELS: 'chat_channels',
  CHAT_THREAD: 'chat_thread',
  HELP: 'help',
  RESOURCES: 'resources'
}

export default COLLECTION