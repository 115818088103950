import Calls from '../../ui/Calls/index'
import {connect} from 'react-redux'
import {fetchTwilioRooms} from '../../../actions/twilio_rooms'
import {addError} from '../../../actions/error'


const mapStateToProps = (state, props) =>
  ({
    companies: state.companies.docs,
    docs: state.twilio_rooms.docs,
    fetching: state.twilio_rooms.fetching
  })

const mapDispatchToProps = dispatch =>
  ({
    fetchTwilioRooms(startDate, endDate, company_id) {
      dispatch(
        fetchTwilioRooms(startDate, endDate, company_id)
      )
    },
    addError(error) {
      dispatch(
        addError(error)
      )
    }
  })


export default connect(mapStateToProps, mapDispatchToProps)(Calls)