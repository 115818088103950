import { createSelector } from 'reselect'
import _ from 'lodash'


export const optionsCalc =  (docs = []) => [{label: "All companies", value: "all"}, ...docs.map(i => ({ label: i.display_name, value: i.company_id }))]

export const datesCalc = docs => _.chain(docs)
	.map(i => i.date_formatted)
	.uniq()
	.value()


export const patientsCalc = docs => _.chain(docs)
	.groupBy(i => i.date_formatted)
	.map((value, date) => _.chain(value)
		.filter(i => i.role === "patient")
		.values()
		.map(j => j.rating)
		.mean()
		.value()
	)
	.value()


export const hcpCalc = docs => _.chain(docs)
	.groupBy(i => i.date_formatted)
	.map((value, date) => _.chain(value)
		.filter(i => i.role === "hcp")
		.values()
		.map(j => j.rating)
		.mean()
		.value()
	)
	.value()


export const dataCalc = docs => [["date", "comment", "rating", "role"], ...docs.map( i => ([
	i.date_formatted ?? "", i.comment.trim() ?? "", i.rating ?? "", i.role ?? ""
]))]


export const getDocs = state => state.docs
export const getCompanyDocs = state => state.companies
export const getOptions = createSelector(getCompanyDocs, optionsCalc)
export const getDates = createSelector(getDocs, datesCalc )
export const getPatients = createSelector( getDocs, patientsCalc )
export const getHCP = createSelector( getDocs, hcpCalc )
export const getData = createSelector( getDocs, dataCalc )
