import React, {Component} from 'react'
import Collapse, {Panel} from '../Collapse'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import QuickSearch from '../QuickSearch'
import EntryHeader from '../EntryHeader'
import Form from './Form'
import '../../../assets/scss/SuperAdmins/Admins.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import LegalInfoFooter from "../LegalInfoFooter"


class CMSAdmins extends Component {

  state = {
    query: ""
  }

  handleQuickSearch = query => this.setState({
    query: query
  })

  handleUpdate = data => this.props.setDoc(data)

  handleDelete = data => this.props.deleteDoc(data)

  render() {
    const { docs, fetching } = this.props
    const { query } = this.state
    const filtered = query.length > 0 ? docs.filter(doc => JSON.stringify(doc).toLowerCase().includes(query)) : docs

    return (
      <main className="app admins">

        {fetching &&
          <ProgressBarIndeterminate />
        }

        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Admins" />
          <QuickSearch handleQuickSearch={this.handleQuickSearch} entries={filtered.length}
            linkTo="admins/add" linkTitle="ADD ADMIN" />

          <section className="content">

            <Collapse accordion={true}>

              {filtered.map(doc =>
                <Panel header={doc.admin_id} headerClass="edit-admin-panel" key={doc.id}>
                  <Form
                    key={doc.id}
                    {...doc}
                    handleDelete={this.handleDelete}
                    handleUpdate={this.handleUpdate}
                  />
                </Panel>
              )}

            </Collapse>

            {filtered.length === 0 &&
              <p>No data</p>
            }

          </section>
        </article>
        <LegalInfoFooter />
      </main>
    )
  }
}


export default CMSAdmins