import React from 'react'
import moment from 'moment-timezone'
import Timezone from "../../../constants/timezone"
import styled from "styled-components"

const ButtonWrapper = styled.button`
  background-color: #00A9F4;
  color: white;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  margin-left: auto;

  &:hover {
    background-color: #0086c1;
  }
`


const Button = ({data}) => {

  const download = (data, filename, type) => {
    const file = new Blob([data], { type: type })
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename)
    } else {
      const a = document.createElement("a")
      const url = URL.createObjectURL(file)
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    }
  }

  const date = () => (new Date()).toISOString()
    .slice(0, 19)
    .replace(/-/g, "-")
    .replace("T", "-")
    .replace(":", "-")

  const csv = data => `${Object.keys(data[0]).join(",")}\n${data.map(i => Object.values(i).join(",")).join("\n")}`

  const handleClick = e => {
    e.preventDefault()

    const d = data.map(d => {

      d["Twilio Call Identification No. (SID)"] = d?.sid ?? "n/a"
      delete d.sid

      d["Date Created"] = moment(d.dateCreated)?.tz(Timezone)?.format("YYYY-MM-DD HH:mm") ?? "n/a"
      delete d.dateCreated

      d["HCP ID"] = d?.nurse_lastname ?? "n/a"
      delete d.nurse_lastname

      d["HCP Call Duration (secs)"] = d?.nurse_duration ?? "n/a"
      delete d.nurse_duration

      d["Patient ID"] = d?.patient_id ?? "n/a"
      delete d.patient_id

      d["Patient Call Duration (secs)"] = d?.patient_duration ?? "n/a"
      delete d.patient_duration

      d["Cost on Call ($)"] = d?.costOfCall ?? "n/a"
      delete d.costOfCall

      d["Call Status"] = d?.call_failed ?? "n/a"
      delete d.call_failed

      return d
    })

    if (data.length > 0)
      download(csv(d), `call-data-${date()}.csv`, "text/csv")
  }

  return (
    <ButtonWrapper
      onClick={e => handleClick(e)}
    >DOWNLOAD CSV</ButtonWrapper>
  )

}


export default Button
