import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import PrivateRoute from '../components/container/PrivateRoute'

import SuperAdmins from '../components/container/SuperAdmins/SuperAdmins'
import AddSuperAdmin from '../components/container/SuperAdmins/AddSuperAdmin'

import CompanyAdmins from '../components/container/CompanyAdmins/CompanyAdmins'
import AddCompanyAdmin from '../components/container/CompanyAdmins/AddCompanyAdmin'

import Appointments from '../components/container/Appointments/Appointments'

import Companies from '../components/container/Companies/Companies'
import AddCompany from '../components/container/Companies/AddCompany'

import HealthcareProfessionals from '../components/container/HealthcareProfessionals/HealthcareProfessionals'
import AddHealthcareProfessional from '../components/container/HealthcareProfessionals/AddHealthcareProfessional'

import Patients from '../components/container/Patients/Patients'
import AddPatient from '../components/container/Patients/AddPatient'

import Calls from '../components/container/Calls/Calls'
import Analytics from '../components/container/Analytics/Analytics'

import Resources from '../components/container/Resources/Resources'
import AddResource from '../components/container/Resources/AddResource'

import Help from '../components/container/Help/Help'
import AddHelp from '../components/container/Help/AddHelp'

import Login from '../components/container/Login/Login'
import Lost from '../components/ui/Lost'


const routes = (
  <Router>
    <Switch>
      <PrivateRoute exact path="/admins" component={SuperAdmins} />
      <PrivateRoute exact path="/admins/add" component={AddSuperAdmin} />

      <PrivateRoute exact path="/company-admins" component={CompanyAdmins} />
      <PrivateRoute exact path="/company-admins/add" component={AddCompanyAdmin} />

      <PrivateRoute exact path="/companies" component={Companies} />
      <PrivateRoute exact path="/companies/add" component={AddCompany} />

      <PrivateRoute exact path="/appointments" component={Appointments} />

      <PrivateRoute exact path="/healthcare-professionals" component={HealthcareProfessionals} />
      <PrivateRoute exact path="/healthcare-professionals/add" component={AddHealthcareProfessional} />

      <PrivateRoute exact path="/patients" component={Patients} />
      <PrivateRoute exact path="/patients/add" component={AddPatient} />

      <PrivateRoute exact path="/calls" component={Calls} />

      <PrivateRoute exact path="/analytics" component={Analytics} />

      <PrivateRoute exact path="/resources" component={Resources} />
      <PrivateRoute exact path="/resources/add" component={AddResource} />

      <PrivateRoute exact path="/help" component={Help} />
      <PrivateRoute exact path="/help/add" component={AddHelp} />

      <Route exact path="/login" component={Login} />

      <PrivateRoute component={Lost} />
    </Switch>
  </Router>
)

export default routes 