import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db, FieldValue} from '../index'
import {addError} from "../error"


const isFetching = fetching =>
  ({
    type: ACTION.HELP_FETCHING_DOCS,
    payload: fetching
  })

export const addDoc = doc => async (dispatch, getState) => {

  dispatch(
    isFetching(true)
  )

  try {
    await db.collection(COLLECTION.HELP).add(doc)

    const increment = FieldValue.increment(1)
    await db.collection(COLLECTION.COUNTERS)
      .doc(COLLECTION.HELP)
      .update({
        total: increment
      })

  } catch (error) {
    dispatch(
      addError(error.message)
    )
  } finally {
    dispatch(
      isFetching(false)
    )
  }

}