import React, {useState} from 'react'
import {Calendar, momentLocalizer, Views} from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import "react-big-calendar/lib/css/react-big-calendar.css"
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import '../../../assets/scss/Appointments/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import ShowErrors from "../../container/ShowErrors"
import Header from "../../container/Header"
import EntryHeader from "../EntryHeader"
import DeleteEvent from "./DeleteEvent"
import SlideDrawer from "./Drawer/Drawer"
import ReactTooltip from "react-tooltip"
import SearchWrapper from './Styled/SearchWrapper'
import ButtonCloseWrapper from "../ButtonCloseWrapper"
import BlueButton from "../BlueButton"
import EventWrapperWrapper from './Styled/EventWrapperWrapper'
import moment from 'moment-timezone'
import Select from "react-select"
import LegalInfoFooter from "../LegalInfoFooter"
import Timezone from "../../../constants/timezone"


Calendar.tz = moment.tz.guess()

const m = (...args) => moment.tz(...args, Calendar.tz)
m.localeData = moment.localeData

const localizer = momentLocalizer(m)

const MonthEventInfo = ({ event }) => {
	return (
		<EventWrapperWrapper>
			<strong>{moment(event.start).format("h:mma")}</strong>&nbsp;
			{event.health_care_professional.hcp_id}-{event.patient.patient_id}
		</EventWrapperWrapper>
	)
}

const WeekEventInfo = ({ event }) => {
	return (
		<EventWrapperWrapper>
			<DeleteEvent />
			{event.patient.patient_id}-{event.health_care_professional.hcp_id}
		</EventWrapperWrapper>
	)
}

const DayEventInfo = ({ event }) => {
	return (
		<EventWrapperWrapper>
			<DeleteEvent />
			Patient ID:&nbsp;{event.patient.patient_id} and HCP ID:&nbsp;{event.health_care_professional.hcp_id}
		</EventWrapperWrapper>
	)
}

const DraggableCalendar = withDragAndDrop(Calendar)

const Appointments = ({
	companies = [],
	company_id = "all",
	fetching = false,
	docs = [],
	query = "",
	appointmentListener,
	cancelAppointment,
	setAppointment,
	rescheduleAppointment,
	addQuery,
	date,
	addDate,
	addCompanyID
}) => {

	const [showValue, setShowValue] = useState(false)

	const copyToClipboard = str => {
		const el = document.createElement('textarea')
		el.value = str
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
	}

	const events = docs.map( i => {
		i.title = `Appointment between Patient ID:${i.patient.patient_id} and HCP ID: ${i.health_care_professional.hcp_id}`
		i.start = i.date.toDate()
		i.end = new Date(i.start.getTime() + 15*60000)
		return i
	})

	const reschedule = obj => {
		rescheduleAppointment(obj.event, obj.start)
	}

	const handleSearchForm = e => {
		e.preventDefault()
		appointmentListener()
	}

	const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : [{ label: 'All Companies', value: 'all' }]

	return (
		<main className="app appointments">

			{fetching &&
			<ProgressBarIndeterminate />
			}

			<ShowErrors />
			<Header />
			<article className="inner-content">

				<EntryHeader title="Appointments" />

				<SearchWrapper>
					<form onSubmit={handleSearchForm}>
						<div id="query-filter">
							<input
								type="text"
								value={query}
								placeholder="Quick search"
								onChange={e => {
									addQuery(e.target.value)
									appointmentListener()
								}}
							/>
							{query.length > 0 &&
							<ButtonCloseWrapper
								onClick={() => {
									addQuery("")
									appointmentListener()
								}}
								type="reset"
							/>
							}
						</div>
						<div id="company-id-filter">
							<Select
							name="filter"
							placeholder="Please select company..."
							value={companies.map(i => ({ label: i.display_name, value: i.company_id })).find(company => company.value === company_id)}
							onChange={selected => {
								addCompanyID(selected.value)
								appointmentListener()
							}}
							options={[ { label: 'All Companies', value: 'all' }, ...options]}
						/>
						</div>

						{events.length > 0 &&
							<p>{events.length} entries</p>
						}
					</form>

					<BlueButton onClick={() => setShowValue(!showValue) }>ADD APPOINTMENT</BlueButton>
					<ReactTooltip />

				</SearchWrapper>


				<section className="content">

					<DraggableCalendar
						selectable
						date={ date }
						events={events}
						localizer={localizer}
						defaultView={Views.MONTH}
						views={[Views.MONTH, Views.WEEK, Views.DAY]}
						drilldownView={Views.DAY}
						popup={false}
						resizeable={false}
						resizableAccessor={() => false}
						components={{
							month: {
								event: MonthEventInfo
							},
							week: {
								event: WeekEventInfo
							},
							day: {
								event: DayEventInfo
							}
						}}
						style={{ height: 900, width: '100%' }}
						onNavigate={ date => {
							addDate(date)
							appointmentListener()
						}}
						onSelectEvent={(event, {target}) => {
							copyToClipboard(`Appointment Date: ${moment(event.date.toDate(), Timezone).format("lll")}\nPatient ID: ${event.patient.patient_id}\nHealthcare Professional ID: ${event.health_care_professional.hcp_id}`)

							if(target.classList.contains('delete-event')){
								cancelAppointment(event)
							} else {
								const eventsContainer = target.closest(".rbc-events-container")
								if(!!eventsContainer){
									[...eventsContainer.querySelectorAll('.rbc-event')]
										.forEach( i => i.style.zIndex = 1 )
									if(target.classList.contains('.rbc-selected')){
										target.style.zIndex = 2
									} else {
										const rbcSelected = target.closest('.rbc-selected')
										if(!!rbcSelected){
											rbcSelected.style.zIndex = 2
										}
									}
								}
							}
						}}
						onEventDrop={ event => reschedule(event)}
						messages={{ showMore: target => <span role="presentation"> ...{target} more appointment{ target > 1 ? 's' : ''}</span> }}
					/>

				</section>

				{showValue &&
					<SlideDrawer
						show={!showValue}
						closeDrawer={ () => setShowValue(false)}
						setAppointment={setAppointment}
					/>
				}

			</article>
			<LegalInfoFooter />
		</main>
	)
}


export default Appointments



