import React, {Component} from 'react'
import {DateRangePicker} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../../assets/scss/Calls/DateRangePickerWrapper.scss'
import moment from "moment"


class DateRangePickerWrapper extends Component {

  constructor(props) {
    super(props)

    this.state = {
      focusedInput: null,
      startDate: this.props.startDate,
      endDate: this.props.endDate
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.props.onDatesChange(startDate, endDate)
    this.setState({ startDate: startDate, endDate: endDate })
  }

  onFocusChange = focusedInput => {
    this.setState({ focusedInput })
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state

    return (
      <DateRangePicker
        startDate={startDate}
        startDateId="your_unique_start_date_id"
        endDate={endDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={this.onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={this.onFocusChange}
        showDefaultInputIcon
        inputIconPosition="after"
        small
        isOutsideRange={day => (moment().diff(day) < 0)}
      />
    )
  }
}

export default DateRangePickerWrapper