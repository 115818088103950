import React, {Component} from 'react'
import '../../../assets/scss/Companies/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import ReactTooltip from 'react-tooltip'
import placeholder from "../../../assets/svg/placeholder.svg"
import {storageRef, Timestamp} from '../../../actions'
import LogoDropzone from './LogoDropzone'
import Close from '../Close'
import getKeywords from "../../../util/getKeywords";


class Form extends Component {

  state = {
    id: this.props.id || null,
    company_id: this.props.company_id || "",
    display_name: this.props.display_name || "",
    title: this.props.title || "",
    first_name: this.props.first_name || "",
    last_name: this.props.last_name || "",
    email: this.props.email || "",
    link: this.props.link || "",
    registered: this.props.registered || Timestamp.now(),
    image_ref: this.props.image_ref || null,
    progress: this.props.progress || 100,
    url: placeholder,
    keywords: this.props.keywords || []
  }

  componentDidMount() {
    if (!!this.props.image_ref && this.props.image_ref.length > 0) {
      this.getImage(this.state.image_ref)
    }
  }

  getImage = async image_ref => {

    this.setState({
      image_ref: image_ref
    })

    try {
      const url = await storageRef.child(image_ref).getDownloadURL()

      this.setState({
        url: url
      })

    } catch (error) {
      console.log(error)
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleDelete = e => {
    e.preventDefault()
    this.props.handleDelete(this.state)
  }

  handleSubmit = e => {
    e.preventDefault()

    const { id, company_id, display_name, image_ref, title, first_name, last_name, email, link, registered } = this.state

    if (company_id.length === 0) {
      this.props.addError("Please provide a Company ID")
    } else if (display_name.length === 0) {
      this.props.addError("Please provide a Company Display Name")
    } else {

      const data = {
        id: id,
        company_id: company_id.toLowerCase().replace(/\s/g, ''),
        display_name: display_name,
        registered: registered
      }

      if (title.length > 0) {
        data.title = title
      }

      if (first_name.length > 0) {
        data.first_name = first_name
      }

      if (last_name.length > 0) {
        data.last_name = last_name
      }

      if (email.length > 0) {
        data.email = email
      }

      if (link.length > 0) {
        data.link = link
      }

      if (image_ref?.length > 0) {
        data.image_ref = image_ref
      }

      data.keywords = Array.from(new Set([
          ...getKeywords(first_name.toLowerCase()),
          ...getKeywords(last_name.toLowerCase()),
          ...getKeywords(email.toLowerCase()),
          ...getKeywords(link.toLowerCase()),
          ...getKeywords(company_id.toLowerCase()),
          ...getKeywords(display_name.toLowerCase())
        ]))

      this.props.handleUpdate(data)
    }

  }

  render() {
    const { url, display_name, company_id, image_ref, title, first_name, last_name, email, link } = this.state

    return (
      <form
        className="edit-company"
        onSubmit={this.handleSubmit}
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
      >

        <div className="company_id">
          <label data-tip={`The title for the item`}>Company ID</label>
          <input
            tabIndex="10"
            name="company_id"
            type="text"
            placeholder="Please enter a Company ID..."
            value={company_id}
            onChange={this.handleChange}
            readOnly
          />
        </div>

        <div className="display_name">
          <label data-tip={`The title for the item`}>Display Name</label>
          <input
            tabIndex="10"
            name="display_name"
            type="text"
            placeholder="Please enter a company Display Name..."
            value={display_name}
            onChange={this.handleChange}
          />
        </div>


        <div className="title">
          <label data-tip={`The contact's title`}>Title</label>
          <input
            tabIndex="10"
            name="title"
            type="text"
            placeholder="Please enter the title..."
            value={title}
            onChange={this.handleChange}
          />
        </div>

        <div className="first_name">
          <label data-tip={`The contact's first name`}>First Name</label>
          <input
            tabIndex="10"
            name="first_name"
            type="text"
            placeholder="Please enter the first name..."
            value={first_name}
            onChange={this.handleChange}
          />
        </div>

        <div className="last_name">
          <label data-tip={`The contact's last name`}>Last Name</label>
          <input
            tabIndex="10"
            name="last_name"
            type="text"
            placeholder="Please enter the last name..."
            value={last_name}
            onChange={this.handleChange}
          />
        </div>

        <div className="email">
          <label data-tip={`The contact's email`}>Email</label>
          <input
            tabIndex="10"
            name="email"
            type="text"
            placeholder="Please enter the email..."
            value={email}
            onChange={this.handleChange}
          />
        </div>

        <div className="link">
          <label data-tip={`The web address link`}>Web Address Link</label>
          <input
            tabIndex="10"
            name="link"
            type="text"
            placeholder="Web Address Link..."
            value={link}
            onChange={this.handleChange}
          />
        </div>

        <div className="logo">
          <label data-tip={`The company logo`}>Logo</label>
          {!!image_ref && image_ref.length > 0 ? (
            <figure>
              <img alt="company logo" src={url} loading="lazy" />
              <Close onClick={() => { this.setState({ image_ref: "" }) }} />
            </figure>
          ) : (
              <LogoDropzone
                getImage={this.getImage}
              />
            )}
        </div>

        <input
          className="trash"
          onClick={this.handleDelete}
          type="image"
          alt="delete item"
          src={trashIcon}
          data-tip={`Click to delete record`}
        />

        <input
          data-tip={`Click to update record`}
          className="update btn"
          type="submit"
          value="UPDATE"
        />

        <ReactTooltip />

      </form>
    )
  }
}


export default Form