import _ from "lodash"
import {helpDocsListener} from './help/listener'
import {resourcesDocListener} from './resources/listener'
import {superAdminsDocListener} from './super_admins/listener'
import {companyAdminsDocListener} from './company_admins/listener'
import {companiesDocListener} from './companies/listener'
import {fetchTwilioRooms} from "./twilio_rooms"
import {getPatients} from './patients/index'
import {healthcareProfessionalsListener} from "./healthcare_professionals/listener"
import {auth} from "./index"
import ACTION from "../constants/actionType"
import {feedbackListener} from "./analytics/feedback"
import {appointmentListener} from "./appointments"


const removeUser = () =>
	({
		type: ACTION.AUTH_LOGOUT,
		payload: null
	})


const setUpListeners = async dispatch => {

	dispatch(
		getPatients()
	)

	dispatch(
		helpDocsListener()
	)

	dispatch(
		resourcesDocListener()
	)

	dispatch(
		superAdminsDocListener()
	)

	dispatch(
		companyAdminsDocListener()
	)

	dispatch(
		companiesDocListener()
	)

	dispatch(
		fetchTwilioRooms()
	)

	dispatch(
		healthcareProfessionalsListener()
	)

	dispatch(
		feedbackListener()
	)

	dispatch(
		appointmentListener()
	)

}

const removeListeners = async () =>{

	if(_.isFunction(window.feedbackDocListener)){
		window.feedbackDocListener()
		delete window.feedbackDocListener
	}

	if(_.isFunction(window.companiesDocListener)){
		window.companiesDocListener()
		delete window.companiesDocListener
	}

	if(_.isFunction(window.companyAdminsDocListener)){
		window.companyAdminsDocListener()
		delete window.companyAdminsDocListener
	}

	if(_.isFunction(window.resourcesDocListener)){
		window.resourcesDocListener()
		delete window.resourcesDocListener
	}

	if(_.isFunction(window.helpDocsListener)){
		window.helpDocsListener()
		delete window.helpDocsListener
	}

	if(_.isFunction(window.healthcareProfessionalsListener)){
		window.healthcareProfessionalsListener()
		delete window.healthcareProfessionalsListener
	}

	if(_.isFunction(window.getPatientsListener)){
		window.getPatientsListener()
		delete window.getPatientsListener
	}

	if(_.isFunction(window.superAdminsDocListener)){
		window.superAdminsDocListener()
		delete window.superAdminsDocListener
	}

	if(_.isFunction(window.appointmentListener)){
		window.appointmentListener()
		delete window.appointmentListener
	}

}

export const dataListeners = () => async (dispatch, getState) => {

	if(_.isFunction(window.authListener)){
		window.authListener()
		delete window.authListener
	}

	window.authListener = auth.onAuthStateChanged(currentUser => {
		 if (currentUser){
			 setUpListeners(dispatch)
		 } else {
			  removeListeners()
			  dispatch(
				  removeUser()
			  )
		 }
	})
}