import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db, FieldValue} from '../index'
import {addError} from "../error"
import _ from 'lodash'


const limit = 25

const isFetching = fetching =>
	({
		type: C.PATIENTS_FETCHING_DOCS,
		payload: fetching
	})

export const addQuery = query =>
	({
		type: C.PATIENTS_QUERY,
		payload: query
	})

export const addCompanyFilter = company_id =>
	({
		type: C.PATIENTS_COMPANY_FILTER,
		payload: company_id
	})

const nextAvailable = isAvailable =>
	({
		type: C.PATIENTS_PAGINATION_NEXT_AVAILABLE,
		payload: isAvailable
	})

const prevAvailable = isAvailable =>
	({
		type: C.PATIENTS_PAGINATION_PREVIOUS_AVAILABLE,
		payload: isAvailable
	})

const remove = doc =>
	({
		type: C.PATIENTS_DELETE_DOC,
		payload: doc
	})

const update = doc =>
	({
		type: C.PATIENTS_UPDATE_DOC,
		payload: doc
	})

const addPatientDoc = doc =>
	({
		type: C.PATIENTS_NEW_DOC,
		payload: doc
	})


export const deleteDoc = doc => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {

		await db.collection(COLLECTION.PATIENTS).doc(doc.id).delete()

		const increment = FieldValue.increment(-1)
		await db.collection(COLLECTION.COUNTERS).doc(COLLECTION.PATIENTS).update({
			total: increment
		})

	} catch (error) {
		dispatch(
			addError(error.message)
		)

		dispatch(
			isFetching(false)
		)
	}
}

export const setDoc = doc => async (dispatch, getState) => {

	dispatch(
		isFetching(true)
	)

	try {
		const docID = doc.id

		const data = {
			company_id: doc.company_id,
			patient_id: doc.patient_id,
			password: doc.password,
			keywords: doc.keywords,
			online: doc.online,
			is_auth: doc.is_auth,
			registered: doc.registered
		}

		if (doc.fcm_token) {
			data.fcm_token = doc.fcm_token
		}

		if (doc.pushkit_token) {
			data.pushkit_token = doc.pushkit_token
		}

		await db.collection(COLLECTION.PATIENTS).doc(docID).set(data)

	} catch (error) {
		dispatch(
			addError(error.message)
		)

		dispatch(
			isFetching(false)
		)
	}

}

const checkForNextDoc = async (docs, query, companyFilter, loadPrev, loadNext) => {
	const latestDoc = _.first(docs)?.registered ?? null
	const oldestDoc = _.last(docs)?.registered ?? null

	let querySnap = db.collection(COLLECTION.PATIENTS)

	if(query.length > 0){
		querySnap = querySnap
			.where("keywords", "array-contains", query)
	}

	if(companyFilter !== 'all'){
		querySnap = querySnap
			.where("company_id", "==", companyFilter)
	}

	if(loadNext){
		querySnap = querySnap
			.orderBy("registered", "desc")
			.startAfter(oldestDoc)
			.limit(1)
	} else if(loadPrev){
		querySnap = querySnap
			.orderBy("registered", "desc")
			.startAfter(oldestDoc)
			.limit(1)
	} else {
		querySnap = querySnap
			.orderBy("registered", "desc")
			.startAfter(oldestDoc)
			.limit(1)
	}

	const snap = await querySnap.get()

	return !snap.empty
}

const checkForPrevDoc = async (docs, query, companyFilter, loadPrev, loadNext) => {

	const latestDoc = _.first(docs)?.registered ?? null
	const oldestDoc = _.last(docs)?.registered ?? null

	let querySnap = db.collection(COLLECTION.PATIENTS)

	if(query.length > 0){
		querySnap = querySnap
			.where("keywords", "array-contains", query)
	}

	if(companyFilter !== 'all'){
		querySnap = querySnap
			.where("company_id", "==", companyFilter)
	}

	querySnap = querySnap
		.orderBy("registered", "desc")
		.endBefore(latestDoc)
		.limitToLast(1)

	const snap = await querySnap
		.get()

	return !snap.empty
}


export const getPatients = (
	loadNext = false,
	loadPrev = false
) => async (dispatch, getState) => {

	const {patients: {docs = [], query = "", companyFilter = "all"}} = getState()

	const latestDoc = _.first(docs)?.registered ?? null
	const oldestDoc = _.last(docs)?.registered ?? null

	let querySnap = db.collection(COLLECTION.PATIENTS)

	if(query.length > 0){
		querySnap = querySnap
			.where("keywords", "array-contains", query.toLowerCase())
	}

	if(companyFilter !== 'all'){
		querySnap = querySnap
			.where("company_id", "==", companyFilter)
	}

	if(loadNext){
		querySnap = querySnap
			.orderBy("registered", "desc")
			.startAfter(oldestDoc)
			.limit(limit)
	} else if(loadPrev){
		querySnap = querySnap
			.orderBy("registered", "desc")
			.endBefore(latestDoc)
			.limitToLast(limit)
	} else {
		querySnap = querySnap
			.orderBy("registered", "desc")
			.limit(limit)
	}

	if (window.getPatientsListener) {
		window.getPatientsListener()
		window.getPatientsListener = null
	}

	dispatch(isFetching(true))

	window.getPatientsListener = querySnap
		.onSnapshot(async querySnapshot => {

			const queryDocs = _.chain(querySnapshot.docs)
				.map(i => ({id: i.id, ...i.data()}))
				.orderBy(['registered'], ['desc'])
				.value()

			const isNextDoc = await checkForNextDoc(queryDocs, query, companyFilter, loadPrev, loadNext)
			const isPrevDoc = await checkForPrevDoc(queryDocs, query, companyFilter, loadPrev, loadNext)

			dispatch(isFetching(false))
			dispatch(nextAvailable(isNextDoc))
			dispatch(prevAvailable(isPrevDoc))

			docs.filter(i => !queryDocs.find( j => i.patient_id === j.patient_id  ))
				.forEach( i => dispatch( remove(i) ) )

			querySnapshot.docChanges().forEach(change => {

				const doc = {id: change.doc.id, ...change.doc.data()}

				switch (change.type) {
					case 'added':
						dispatch(
							addPatientDoc(doc)
						)
						break
					case 'modified':
						dispatch(
							update(doc)
						)
						break
					case 'removed':
						dispatch(
							remove(doc)
						)
						break
					default:
						break
				}
			})
		}, error => {
			dispatch(
				isFetching(false)
			)
			dispatch(
				addError(error.message)
			)
		})
}
