import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import Checkbox from './Checkbox'
import getKeywords from '../../../util/getKeywords'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/Help/AddHelp.scss'
import ReactTooltip from 'react-tooltip'
import roleType from '../../../constants/roleType'
import Select from 'react-select'
import isUrlValid from '../../../util/isUrlValid'
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'
import ButtonCloseWrapper from "../ButtonWithCloseIcon"
import LegalInfoFooter from "../LegalInfoFooter"


class AddHelp extends Component {

  state = {
    company_id: { label: 'All', value: 'all' },
    title: "",
    body: "",
    type: {
      label: "All",
      value: "all"
    },
    index: 1,
    withLink: false,
    linkTitle: null,
    linkURL: null,
    keywords: []
  }

  handleChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (target.type === 'checkbox' && !value) {
      this.setState({
        linkTitle: null,
        linkURL: null
      })
    }

    this.setState({
      [name]: value
    })
  }

  handleReset = e => {
    e.preventDefault()
    this.setState({
      company_id: { label: 'All', value: 'all' },
      title: "",
      body: "",
      type: {
        label: "All",
        value: "all"
      },
      index: 1,
      withLink: false,
      linkTitle: null,
      linkURL: null,
      keywords: []
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { company_id, title, body, linkTitle, linkURL, type, index, withLink } = this.state

    if (!company_id) {
      this.props.addError("Please select a company id")
    } else if (title.length === 0) {
      this.props.addError("Please enter a title")
    } else if (body.length === 0) {
      this.props.addError("Please enter a body")
    } else if (withLink && linkTitle.length === 0) {
      this.props.addError("Please a a link title")
    } else if (withLink && !isUrlValid(linkURL)) {
      this.props.addError("Please a valid link url")
    } else {
      const data = {
        company_id: company_id.value,
        title: title,
        body: body,
        type: type.value,
        index: `${index}`,
        withLink: withLink
      }

      if (withLink) {
        data.linkURL = linkURL
        data.linkTitle = linkTitle
      }

      data.keywords = Array.from(new Set([
        ...getKeywords(title.toLowerCase()),
        ...getKeywords(body.toLowerCase())
      ]))

      this.props.addDoc(data)
      this.props.history.push('/help')
    }

  }

  handleFieldReset = e => {
    e.preventDefault()
    const field = e.currentTarget.dataset.field ?? ""
    if(!!field){
      this.setState({
        [field]: ""
      })
    }
  }

  handleReset = e => {
    e.preventDefault()
    this.setState({
      [e.target.dataset.field]: ""
    })
  }

  handleFullReset = e => {
    e.preventDefault()
    this.setState({
      company_id: { label: 'All', value: 'all' },
      title: "",
      body: "",
      type: {
        label: "All",
        value: "all"
      },
      index: 1,
      withLink: false,
      linkTitle: null,
      linkURL: null,
      keywords: []
    })
  }

  render() {
    const { company_id, title, body, linkTitle, linkURL, type, index, withLink } = this.state
    const { companies } = this.props

    const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

    return (
      <main className="app add-help">
        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Add Help" />

          <section className="content">

            <h3>New Help</h3>
            <Link to="/help" className="close" data-tip={`Click to close`}><img src={close} alt="close" /></Link>

            <form
              className="add-help-form"
              onSubmit={this.handleSubmit}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
            >

              <div className="company_id">
                <label data-tip={`The company id for the item`}>Company ID</label>
                <Select
                  name="company_id"
                  value={company_id}
                  onChange={selected => this.setState({ company_id: selected })}
                  options={[ { label: 'All', value: 'all' }, ...options]}
                />
              </div>

              <div className="title">
                <label data-tip={`The title for the item`}>Title</label>
                <input
                  tabIndex="10"
                  name="title"
                  type="text"
                  placeholder="title"
                  value={title}
                  onChange={this.handleChange}
                />
                {title.length > 0 &&
                  <ButtonCloseWrapper data-field="title" onClick={this.handleFieldReset}  type="reset" />
                }
              </div>

              <div className="type">
                <label data-tip={`The group for the item`}>Group</label>
                <Select
                  name="type"
                  value={type}
                  onChange={selected => {
                    this.setState({ type: selected })
                  }}
                  options={roleType}
                />
              </div>

              <Checkbox
                title="Link"
                name="withLink"
                withLink={withLink}
                handleChange={this.handleChange}
              />
              {this.state.withLink &&
                <>
                  <div className="linkTitle">
                    <label data-tip={`The link title`}>Link title</label>
                    <input
                      tabIndex="30"
                      name="linkTitle"
                      type="text"
                      placeholder="link title"
                      value={linkTitle}
                      onChange={this.handleChange}
                    />
                    {!!linkTitle && linkTitle.length > 0 &&
                      <ButtonCloseWrapper data-field="linkTitle" onClick={this.handleFieldReset} type="reset" />
                    }
                  </div>

                  <div className="linkURL">
                    <label data-tip={`The link url`}>Link Url</label>
                    <input
                      tabIndex="40"
                      name="linkURL"
                      type="text"
                      placeholder="link url"
                      value={linkURL}
                      onChange={this.handleChange}
                    />
                    {!!linkURL && linkURL.length > 0 &&
                      <ButtonCloseWrapper data-field="linkURL" onClick={this.handleFieldReset} type="reset" />
                    }
                  </div>
                </>
              }

              <div className="body">
                <label data-tip={`The description for the item`}>Description (500 word limit)</label>
                <textarea
                  tabIndex="50"
                  name="body"
                  value={body}
                  onChange={this.handleChange}
                />
              </div>

              <div className="index">
                <label data-tip={`The rank order for the item`}>Order</label>
                <InputNumber
                  name="index"
                  min={0}
                  defaultValue={0}
                  value={index}
                  onChange={ v => this.setState({index: v})}
                />
              </div>

              <input
                tabIndex="60"
                className="reset"
                onClick={this.handleFullReset}
                type="image"
                src={trashIcon}
                alt="reset"
                data-tip={`Click to reset record`}
              />

              <input
                data-tip={`Click to add record`}
                tabIndex="70"
                className="add btn"
                type="submit"
                value="ADD HELP"
              />

              <ReactTooltip />

            </form>

          </section>
        </article>
        <LegalInfoFooter />
      </main>
    )
  }
}


export default AddHelp