import {combineReducers} from 'redux'
import auth from './auth'
import appointments from './appointments'
import errors from './errors'
import help from './help'
import resources from './resources'
import twilio_rooms from './twilio_rooms'
import companies from './companies'
import super_admins from './super_admins'
import company_admins from './company_admins'
import healthcare_professionals from './healthcare_professionals'
import patients from './patients'
import feedback from './analytics'


export default combineReducers({
  errors: errors,
  auth: auth,
  help: help,
  appointments: appointments,
  resource: resources,
  twilio_rooms: twilio_rooms,
  feedback: feedback,
  healthcare_professionals: healthcare_professionals,
  patients: patients,
  companies: companies,
  super_admins: super_admins,
  company_admins: company_admins
})