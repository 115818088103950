import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Timestamp} from '../../../actions'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/HealthcareProfessionals/Add.scss'
import getKeywords from '../../../util/getKeywords'
import getPassword from '../../../util/getPassword'
import ReactTooltip from 'react-tooltip'
import Select from "react-select"
import ButtonCloseWrapper from "../ButtonWithCloseIcon"
import LegalInfoFooter from "../LegalInfoFooter"


class AddHealthcareProfessional extends Component {

	state = {
		company_id: null,
		hcp_id: "",
		password: getPassword(),
		online: {
			status: false,
			last_updated: Timestamp.now()
		},
		is_auth: false,
		registered: Timestamp.now()
	}


	handleChange = e => this.setState({[e.target.name]: e.target.value})


	handleDelete = e => {
		e.preventDefault()
		this.setState({
			company_id: null,
			hcp_id: "",
			password: getPassword(),
			online: {
				status: false,
				last_updated: Timestamp.now()
			},
			is_auth: false,
			registered: Timestamp.now()
		})
	}

	handleSubmit = e => {
		e.preventDefault()

		const {
			company_id,
			hcp_id,
			password,
			online,
			is_auth = false,
			registered = Timestamp.now()
		} = this.state

		if (!company_id) {
			this.props.addError("Please select a company")
		} else if (hcp_id.length === 0) {
			this.props.addError("Please enter a healthcare professional id")
		} else if (password.length === 0) {
			this.props.addError("Please enter a password")
		} else {
			const data = {
				company_id: company_id.value,
				hcp_id: hcp_id.toLowerCase().replace(/\s/g, ''),
				password: password.toLowerCase().replace(/\s/g, ''),
				online: online,
				is_auth: is_auth,
				registered: registered
			}

			data.keywords = Array.from(new Set([
				...getKeywords(hcp_id.toLowerCase())
			]))

			this.props.addDoc(data)
			this.props.history.push('/healthcare-professionals')
		}
	}

	handleFieldReset = e => {
		e.preventDefault()
		const field = e.currentTarget.dataset.field ?? ""
		if(!!field){
			this.setState({
				[field]: ""
			})
		}
	}

	render() {
		const {company_id, hcp_id, password} = this.state
		const { companies } = this.props

		const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

		return (
			<main className="app add-healthcare-professional">
				<ShowErrors/>
				<Header/>
				<article className="inner-content">
					<EntryHeader title="Add Healthcare Professional"/>

					<section className="content">

						<h3>New Healthcare Professional</h3>
						<Link to="/healthcare-professionals" className="close" data-tip={`Click to close`}><img src={close} alt=""/></Link>

						<form
							className="add-healthcare-professional-form"
							onSubmit={this.handleSubmit}
							onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
						>

							<div className="company_id">

								<label data-tip={`The Company ID for the item`}>Company ID</label>

								<Select
									name="company_id"
									value={company_id}
									placeholder="Please select company..."
									onChange={selected => this.setState({ company_id: selected })}
									options={options}
								/>
								<p className="form-help">Please a company for the Healthcare Professional</p>
							</div>

							<div className="hcp_id">
								<label data-tip={`Healthcare Professional ID`}>Healthcare Professional ID</label>
								<input
									name="hcp_id"
									type="text"
									placeholder="Healthcare Professional ID..."
									value={hcp_id}
									onChange={this.handleChange}
								/>
								{hcp_id.length > 0 &&
								<ButtonCloseWrapper data-field="hcp_id" onClick={this.handleFieldReset} type="reset"/>
								}
								<p className="form-help">Please enter a unique Healthcare Professional ID</p>
							</div>

							<div className="password">
								<label data-tip={`Password for HCP ID`}>Password</label>
								<input
									name="password"
									type="text"
									placeholder="password"
									value={password}
									onChange={this.handleChange}
								/>
								{password.length > 0 &&
								<ButtonCloseWrapper data-field="password" onClick={this.handleFieldReset} type="reset"/>
								}
								<p className="form-help">Please enter a password for the Healthcare Professional</p>
							</div>

							<input
								className="reset"
								onClick={this.handleDelete}
								type="image"
								alt=""
								src={trashIcon}
								data-tip={`Click to reset record`}
							/>

							<input
								data-tip={`Click to add record`}
								className="add btn"
								type="submit"
								value="ADD HEALTHCARE PROFESSIONAL"
							/>

							<ReactTooltip/>

						</form>

					</section>

				</article>
				<LegalInfoFooter />
			</main>
		)
	}
}

export default AddHealthcareProfessional