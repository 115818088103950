import React, {Component} from 'react'
import times from '../../../assets/svg/times.svg'
import '../../../assets/scss/ShowErrors.scss'


class ShowErrors extends Component {

  state = {
    noOfErrors: 0,
    newErrorAdded: false,
    animate: false
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      this.setState({ animate: true })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const newErrors = this.props?.errors?.length ?? 0
    const oldErrors = prevProps?.errors?.length ?? 0

    if (newErrors > oldErrors && !prevState.newErrorAdded) {
      this.setState({
        newErrorAdded: true
      })
    } else if (newErrors < oldErrors) {
      this.setState({
        newErrorAdded: false
      })
    }
  }

  render() {
    const { onClearError, errors = [] } = this.props
    const { newErrorAdded, animate } = this.state

    return (
      <aside className="show-errors">
        {(errors.length) ?
          errors.map((message, i) =>
            <div key={i}
              className={(errors.length === (i + 1) && newErrorAdded && animate ? 'error slideInRight' : 'error')}>
              <p>{message}</p>
              <button onClick={() => onClearError(i)}><img src={times} alt="close" /></button>
            </div>
          ) : null
        }
      </aside>
    )
  }
}

export default ShowErrors