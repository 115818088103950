import Patients from '../../ui/Patients'
import {connect} from 'react-redux'
import {addCompanyFilter, addQuery, deleteDoc, getPatients, setDoc} from '../../../actions/patients'
import {addError} from '../../../actions/error'


const mapStateToProps = (state, props) =>
	({
		docs: state.patients.docs,
		companies: state.companies.docs,
		fetching: state.patients.fetching,
		query: state.patients.query,
		companyFilter: state.patients.companyFilter,
		nextAvailable: state.patients.nextAvailable,
		prevAvailable: state.patients.prevAvailable
	})

const mapDispatchToProps = dispatch =>
	({
		deleteDoc(doc) {
			dispatch(
				deleteDoc(doc)
			)
		},
		setDoc(doc) {
			dispatch(
				setDoc(doc)
			)
		},
		addCompanyFilter(company_id) {
			dispatch(
				addCompanyFilter(company_id)
			)
		},
		addQuery(query) {
			dispatch(
				addQuery(query)
			)
		},
		addError(error) {
			dispatch(
				addError(error)
			)
		},
		getPatients(loadNext, loadPrev) {
			dispatch(
				getPatients(loadNext, loadPrev)
			)
		}
	})

export default connect(mapStateToProps, mapDispatchToProps)(Patients)  
