import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/CompanyAdmins/Add.scss'
import getKeywords from '../../../util/getKeywords'
import ReactTooltip from 'react-tooltip'
import {Timestamp} from '../../../actions'
import Select from "react-select"
import ButtonCloseWrapper from "../ButtonWithCloseIcon"
import LegalInfoFooter from "../LegalInfoFooter"


class AddCompanyAdmin extends Component {

  state = {
    admin_id: "",
    company_id: null,
    telephone: "",
    is_auth: false,
    keywords: [],
    registered: Timestamp.now()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleDelete = e => {
    e.preventDefault()
    this.setState({
      admin_id: "",
      company_id: null,
      telephone: "",
      is_auth: false,
      keywords: [],
      registered: Timestamp.now()
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { admin_id, company_id, telephone, is_auth, registered } = this.state

    if (!company_id) {
      this.props.addError("Please select a company id")
    } else if (admin_id.length === 0) {
      this.props.addError("Please enter an Admin ID")
    } else if (telephone.length === 0) {
      this.props.addError("Please enter a telephone")
    } else {
      const data = {
        admin_id: admin_id.toLowerCase().replace(/\s/g, ''),
        telephone: telephone.toLowerCase().replace(/\s/g, ''),
        is_auth: is_auth,
        registered: registered,
        company_id: company_id.value
      }

      data.keywords = Array.from(new Set([
        ...getKeywords(admin_id.toLowerCase())
      ]))

      this.props.addDoc(data)
      this.props.history.push('/company-admins')
    }

  }

  handleAdminIDReset = e => {
    e.preventDefault()
    this.setState({
      admin_id: ""
    })
  }

  handleTelephoneReset = e => {
    e.preventDefault()
    this.setState({
      telephone: ""
    })
  }


  render() {
    const { admin_id, company_id, telephone } = this.state
    const { companies } = this.props

    const options = !!companies ? companies.map(i => ({ label: i.display_name, value: i.company_id })) : []

    return (
      <main className="app add-company-admin">
        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Add Company Admin" />

          <section className="content">

            <h3>New Company Admin</h3>
            <Link to="/company-admins" className="close" data-tip={`Click to close`}>
              <img src={close} alt="" />
            </Link>

            <form
              className="add-admin-form"
              onSubmit={this.handleSubmit}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
            >

              <div className="company_id">
                <label data-tip={`The Company ID for the item`}>Company ID</label>
                <Select
                  name="company_id"
                  value={company_id}
                  onChange={selected => this.setState({ company_id: selected })}
                  options={options}
                />
              </div>

              <div className="admin_id">
                <label data-tip={`CMS Admin ID`}>Admin ID</label>
                <input
                  name="admin_id"
                  type="text"
                  placeholder="Please enter an Admin ID..."
                  value={admin_id}
                  onChange={this.handleChange}
                />
                {admin_id.length > 0 &&
                  <ButtonCloseWrapper
                    onClick={this.handleAdminIDReset}
                    type="reset"
                  />
                }
              </div>

              <div className="telephone">
                <label data-tip={`Telephone for Admin ID`}>Telephone</label>
                <input
                  name="telephone"
                  type="text"
                  placeholder="Please enter a telephone..."
                  value={telephone}
                  onChange={this.handleChange}
                />
                {telephone.length > 0 &&
                  <ButtonCloseWrapper
                    onClick={this.handleTelephoneReset}
                    type="reset"
                  />
                }
              </div>

              <input
                className="reset"
                onClick={this.handleDelete}
                type="image"
                alt=""
                src={trashIcon}
                data-tip={`Click to reset record`}
              />

              <input
                data-tip={`Click to add record`}
                className="add btn"
                type="submit"
                value="ADD COMPANY ADMIN"
              />

              <ReactTooltip />

            </form>

          </section>

        </article>
        <LegalInfoFooter />
      </main>
    )
  }
}

export default AddCompanyAdmin