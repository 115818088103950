import React, {Component} from 'react'
import KPI from './KPI'
import Button from './Button'
import Header from '../../container/Header'
import moment from 'moment'
import DateRangePickerWrapper from './DateRangePickerWrapper'
import CallsTable from './CallsTable'
import EntryHeader from '../EntryHeader'
import Spinner from 'react-spinkit'
import ShowErrors from '../../container/ShowErrors'
import _ from 'lodash'
import '../../../assets/scss/Calls/Calls.scss'
import {Timestamp} from "../../../actions"
import Select from 'react-select'
import Role from '../../../constants/Role'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import LegalInfoFooter from "../LegalInfoFooter"


class Calls extends Component {

  state = {
    query: "",
    startDate: moment().subtract(7, 'd'),
    endDate: moment(),
    company_id: { label: 'All Companies', value: 'all' },
  }

  componentDidMount() {
    this.fetchRooms = _.debounce(this.fetchRooms, 1000)
  }

  fetchRooms = () => {
    const { startDate, endDate, company_id } = this.state
    console.log(startDate)
    console.log(endDate)

    if(!!startDate && !!endDate){
      this.props.fetchTwilioRooms(startDate, endDate, company_id.value)
    }
  }

  onDatesChange = (startDate, endDate) => this.setState({
    startDate: startDate,
    endDate: endDate
  }, this.fetchRooms())


  getCalls = (docs = []) => docs.map(doc => {
      const nParticipant = doc?.participants.filter(d => d.identity.includes("HCP")) ?? []
      const pParticipant = doc?.participants.filter(d => d.identity.includes("Patient")) ?? []
      const hcpID = nParticipant[0]?.identity ?? "n/a"
      const patientDuration = pParticipant?.reduce((total, item) => total + item.duration, 0) ?? 0
      const hcpDuration = nParticipant?.reduce((total, item) => total + item.duration, 0) ?? 0
      const patientID = pParticipant[0]?.identity ?? "n/a"
      const costOfCall = ((hcpDuration + patientDuration) * 0.004 / 60).toFixed(3)

      const timestamp = new Timestamp(doc.dateCreated.seconds, doc.dateCreated.nanoseconds)

      return {
        sid: doc?.sid ?? "n/a",
        date_created: timestamp.toDate(),
        call_failed: doc.participants.length <= 1 ? "missed" : "answered",
        hcp_id: hcpID,
        patient_id: patientID,
        patient_duration: patientDuration,
        hcp_duration: hcpDuration,
        cost_of_call: costOfCall
      }
    })

  render() {
    const { docs = [], fetching = false, companies = [] } = this.props
    const { startDate, endDate, company_id } = this.state
    const options = companies.map(i => ({ label: i.display_name, value: i.company_id }))

    const calls = this.getCalls(this.props.docs || [])
    const numberOfCalls = docs?.length ?? 0
    const numberOfFailedCalls = docs?.filter(doc => doc?.participants?.length <= 1)?.length ?? 0
    const lengthOfCalls = docs?.reduce((total, item) => total + (item?.duration ?? 0), 0) ?? 0
    const totalLengthOfCalls = docs?.map(doc => doc.participants)?.flat()?.reduce((total, item) => total + item.duration, 0) ?? 0
    const costOfCalls = (totalLengthOfCalls / 60 * 0.004)?.toFixed(3) ?? 0.00
    const participants = Array.from(new Set([...docs.map(doc => doc.participants).flat().map(doc => doc.identity)]))
    const activeHCP = participants?.filter(item => item.includes(Role.HEALTH_CARE_PROFESSIONAL))?.length ?? 0
    const activePatients = participants?.filter(item => item.includes(Role.PATIENT))?.length ?? 0


    return (
      <main className="app admins">

        {fetching &&
          <ProgressBarIndeterminate />
        }

        <ShowErrors />
        <Header />
        <article className="inner-content">
          <EntryHeader title="Calls" />

          <section className="Calls">

            <div className="call-controls">
              <Select
                id="filter-by-company-id"
                name="filter"
                value={company_id}
                onChange={selected => this.setState({ company_id: selected }, this.fetchRooms())}
                options={[ { label: 'All Companies', value: 'all' }, ...options]}
              />

              <DateRangePickerWrapper
                startDate={startDate}
                endDate={endDate}
                onDatesChange={this.onDatesChange}
              />

              <Button data={calls} />

            </div>

            {fetching ? (
              <Spinner name="three-bounce" />
            ) : (
                <>
                  <aside className="kpi-indicators">
                    <KPI
                      key="total-calls"
                      title="Total Calls"
                      amount={numberOfCalls.toString()}
                    />
                    <KPI
                      key="total-missed-calls"
                      title="Total Missed Calls"
                      amount={numberOfFailedCalls.toString()}
                    />
                    <KPI
                      key="total-call-time"
                      title="Total Call Time (secs)"
                      amount={`${lengthOfCalls}`}
                    />
                    <KPI
                      key="total-call-cost"
                      title="Total Call Cost"
                      amount={`$${costOfCalls}`}
                    />
                    <KPI
                      key="active-hcp"
                      title="Active HCP"
                      amount={activeHCP.toString()}
                    />
                    <KPI
                      key="Active Patients"
                      title="Active Patients"
                      amount={activePatients.toString()}
                    />
                  </aside>

                  <CallsTable
                    data={calls}
                  />
                </>
              )}
          </section>
        </article>
        <LegalInfoFooter />
      </main>
    )
  }

}

export default Calls
